"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OneShotUserAccountSubscriber = void 0;
const basicUserAccountSubscriber_1 = require("./basicUserAccountSubscriber");
/**
 * Simple implementation of UserAccountSubscriber. It will fetch the UserAccount
 * date on subscribe (or call to fetch) if no account data is provided on init.
 * Expect to use only 1 RPC call unless you call fetch repeatedly.
 */
class OneShotUserAccountSubscriber extends basicUserAccountSubscriber_1.BasicUserAccountSubscriber {
    constructor(program, userAccountPublicKey, data, slot, commitment) {
        super(userAccountPublicKey, data, slot);
        this.program = program;
        this.commitment = commitment !== null && commitment !== void 0 ? commitment : 'confirmed';
    }
    async subscribe(userAccount) {
        if (userAccount) {
            this.user = { data: userAccount, slot: this.user.slot };
            return true;
        }
        await this.fetchIfUnloaded();
        if (this.doesAccountExist()) {
            this.eventEmitter.emit('update');
        }
        return true;
    }
    async fetchIfUnloaded() {
        if (this.user.data === undefined) {
            await this.fetch();
        }
    }
    async fetch() {
        var _a, _b;
        try {
            const dataAndContext = await this.program.account.user.fetchAndContext(this.userAccountPublicKey, this.commitment);
            if (dataAndContext.context.slot > ((_b = (_a = this.user) === null || _a === void 0 ? void 0 : _a.slot) !== null && _b !== void 0 ? _b : 0)) {
                this.user = {
                    data: dataAndContext.data,
                    slot: dataAndContext.context.slot,
                };
            }
        }
        catch (e) {
            console.error(`OneShotUserAccountSubscriber.fetch() UserAccount does not exist: ${e.message}`);
        }
    }
}
exports.OneShotUserAccountSubscriber = OneShotUserAccountSubscriber;
