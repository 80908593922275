"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MaxOverSlotsStrategy = void 0;
class MaxOverSlotsStrategy {
    calculate(samples) {
        if (samples.length === 0) {
            return 0;
        }
        // Assuming samples are sorted in descending order of slot.
        let currMaxFee = samples[0].prioritizationFee;
        for (let i = 0; i < samples.length; i++) {
            currMaxFee = Math.max(samples[i].prioritizationFee, currMaxFee);
        }
        return currMaxFee;
    }
}
exports.MaxOverSlotsStrategy = MaxOverSlotsStrategy;
