"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PollingSubscription = void 0;
class PollingSubscription {
    constructor({ orderSubscriber, frequency, }) {
        this.orderSubscriber = orderSubscriber;
        this.frequency = frequency;
    }
    async subscribe() {
        if (this.intervalId) {
            return;
        }
        this.intervalId = setInterval(this.orderSubscriber.fetch.bind(this.orderSubscriber), this.frequency);
        await this.orderSubscriber.fetch();
    }
    async unsubscribe() {
        if (this.intervalId) {
            clearInterval(this.intervalId);
            this.intervalId = undefined;
        }
    }
}
exports.PollingSubscription = PollingSubscription;
