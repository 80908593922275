"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.bulkPollingUserStatsSubscribe = void 0;
/**
 * @param userStats
 * @param accountLoader
 */
async function bulkPollingUserStatsSubscribe(userStats, accountLoader) {
    if (userStats.length === 0) {
        await accountLoader.load();
        return;
    }
    await Promise.all(userStats.map((userStat) => {
        return userStat.accountSubscriber.addToAccountLoader();
    }));
    await accountLoader.load();
    await Promise.all(userStats.map(async (userStat) => {
        return userStat.subscribe();
    }));
}
exports.bulkPollingUserStatsSubscribe = bulkPollingUserStatsSubscribe;
