"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PriorityFeeSubscriberMap = void 0;
const driftPriorityFeeMethod_1 = require("./driftPriorityFeeMethod");
const types_1 = require("./types");
/**
 * takes advantage of /batchPriorityFees endpoint from drift hosted priority fee service
 */
class PriorityFeeSubscriberMap {
    constructor(config) {
        var _a;
        this.frequencyMs = config.frequencyMs;
        this.frequencyMs =
            (_a = config.frequencyMs) !== null && _a !== void 0 ? _a : types_1.DEFAULT_PRIORITY_FEE_MAP_FREQUENCY_MS;
        this.driftPriorityFeeEndpoint = config.driftPriorityFeeEndpoint;
        this.driftMarkets = config.driftMarkets;
        this.feesMap = new Map();
        this.feesMap.set('perp', new Map());
        this.feesMap.set('spot', new Map());
    }
    updateFeesMap(driftPriorityFeeResponse) {
        driftPriorityFeeResponse.forEach((fee) => {
            this.feesMap.get(fee.marketType).set(fee.marketIndex, fee);
        });
    }
    async subscribe() {
        if (this.intervalId) {
            return;
        }
        await this.load();
        this.intervalId = setInterval(this.load.bind(this), this.frequencyMs);
    }
    async unsubscribe() {
        if (this.intervalId) {
            clearInterval(this.intervalId);
            this.intervalId = undefined;
        }
    }
    async load() {
        try {
            if (!this.driftMarkets) {
                return;
            }
            const fees = await (0, driftPriorityFeeMethod_1.fetchDriftPriorityFee)(this.driftPriorityFeeEndpoint, this.driftMarkets.map((m) => m.marketType), this.driftMarkets.map((m) => m.marketIndex));
            this.updateFeesMap(fees);
        }
        catch (e) {
            console.error('Error fetching drift priority fees', e);
        }
    }
    updateMarketTypeAndIndex(driftMarkets) {
        this.driftMarkets = driftMarkets;
    }
    getPriorityFees(marketType, marketIndex) {
        var _a;
        return (_a = this.feesMap.get(marketType)) === null || _a === void 0 ? void 0 : _a.get(marketIndex);
    }
}
exports.PriorityFeeSubscriberMap = PriorityFeeSubscriberMap;
/** Example usage:
async function main() {
    const driftMarkets: DriftMarketInfo[] = [
        { marketType: 'perp', marketIndex: 0 },
        { marketType: 'perp', marketIndex: 1 },
        { marketType: 'spot', marketIndex: 2 }
    ];

    const subscriber = new PriorityFeeSubscriberMap({
        driftPriorityFeeEndpoint: 'https://dlob.drift.trade',
        frequencyMs: 5000,
        driftMarkets
    });
    await subscriber.subscribe();

    for (let i = 0; i < 20; i++) {
        await new Promise(resolve => setTimeout(resolve, 1000));
        driftMarkets.forEach(market => {
            const fees = subscriber.getPriorityFees(market.marketType, market.marketIndex);
            console.log(`Priority fees for ${market.marketType} market ${market.marketIndex}:`, fees);
        });
    }


    await subscriber.unsubscribe();
}

main().catch(console.error);
*/
