"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OpenbookV2FulfillmentConfigMap = void 0;
class OpenbookV2FulfillmentConfigMap {
    constructor(driftClient) {
        this.map = new Map();
        this.driftClient = driftClient;
    }
    async add(marketIndex, openbookV2MarketAddress) {
        const account = await this.driftClient.getOpenbookV2FulfillmentConfig(openbookV2MarketAddress);
        this.map.set(marketIndex, account);
    }
    get(marketIndex) {
        return this.map.get(marketIndex);
    }
}
exports.OpenbookV2FulfillmentConfigMap = OpenbookV2FulfillmentConfigMap;
