"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSortFn = void 0;
function clientSortAscFn() {
    return 'less than';
}
function clientSortDescFn() {
    return 'greater than';
}
function blockchainSortFn(currentEvent, newEvent) {
    if (currentEvent.slot == newEvent.slot) {
        return currentEvent.txSigIndex < newEvent.txSigIndex
            ? 'less than'
            : 'greater than';
    }
    return currentEvent.slot < newEvent.slot ? 'less than' : 'greater than';
}
function getSortFn(orderBy, orderDir) {
    if (orderBy === 'client') {
        return orderDir === 'asc' ? clientSortAscFn : clientSortDescFn;
    }
    return blockchainSortFn;
}
exports.getSortFn = getSortFn;
