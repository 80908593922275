"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isAmmDrawdownPause = exports.isOperationPaused = exports.ammPaused = exports.fillPaused = exports.exchangePaused = void 0;
const numericConstants_1 = require("../constants/numericConstants");
const types_1 = require("../types");
const anchor_1 = require("@coral-xyz/anchor");
function exchangePaused(state) {
    return state.exchangeStatus !== types_1.ExchangeStatus.ACTIVE;
}
exports.exchangePaused = exchangePaused;
function fillPaused(state, market) {
    if ((state.exchangeStatus & types_1.ExchangeStatus.FILL_PAUSED) ===
        types_1.ExchangeStatus.FILL_PAUSED) {
        return true;
    }
    if (market.hasOwnProperty('amm')) {
        return isOperationPaused(market.pausedOperations, types_1.PerpOperation.FILL);
    }
    else {
        return isOperationPaused(market.pausedOperations, types_1.SpotOperation.FILL);
    }
}
exports.fillPaused = fillPaused;
function ammPaused(state, market) {
    if ((state.exchangeStatus & types_1.ExchangeStatus.AMM_PAUSED) ===
        types_1.ExchangeStatus.AMM_PAUSED) {
        return true;
    }
    if (market.hasOwnProperty('amm')) {
        const operationPaused = isOperationPaused(market.pausedOperations, types_1.PerpOperation.AMM_FILL);
        if (operationPaused) {
            return true;
        }
        if (isAmmDrawdownPause(market)) {
            return true;
        }
    }
    return false;
}
exports.ammPaused = ammPaused;
function isOperationPaused(pausedOperations, operation) {
    return (pausedOperations & operation) > 0;
}
exports.isOperationPaused = isOperationPaused;
function isAmmDrawdownPause(market) {
    let quoteDrawdownLimitBreached;
    if ((0, types_1.isVariant)(market.contractTier, 'a') ||
        (0, types_1.isVariant)(market.contractTier, 'b')) {
        quoteDrawdownLimitBreached = market.amm.netRevenueSinceLastFunding.lte(numericConstants_1.DEFAULT_REVENUE_SINCE_LAST_FUNDING_SPREAD_RETREAT.muln(400));
    }
    else {
        quoteDrawdownLimitBreached = market.amm.netRevenueSinceLastFunding.lte(numericConstants_1.DEFAULT_REVENUE_SINCE_LAST_FUNDING_SPREAD_RETREAT.muln(200));
    }
    if (quoteDrawdownLimitBreached) {
        const percentDrawdown = market.amm.netRevenueSinceLastFunding
            .mul(numericConstants_1.PERCENTAGE_PRECISION)
            .div(anchor_1.BN.max(market.amm.totalFeeMinusDistributions, numericConstants_1.ONE));
        let percentDrawdownLimitBreached;
        if ((0, types_1.isVariant)(market.contractTier, 'a')) {
            percentDrawdownLimitBreached = percentDrawdown.lte(numericConstants_1.PERCENTAGE_PRECISION.divn(50).neg());
        }
        else if ((0, types_1.isVariant)(market.contractTier, 'b')) {
            percentDrawdownLimitBreached = percentDrawdown.lte(numericConstants_1.PERCENTAGE_PRECISION.divn(33).neg());
        }
        else if ((0, types_1.isVariant)(market.contractTier, 'c')) {
            percentDrawdownLimitBreached = percentDrawdown.lte(numericConstants_1.PERCENTAGE_PRECISION.divn(25).neg());
        }
        else {
            percentDrawdownLimitBreached = percentDrawdown.lte(numericConstants_1.PERCENTAGE_PRECISION.divn(20).neg());
        }
        if (percentDrawdownLimitBreached) {
            return true;
        }
    }
    return false;
}
exports.isAmmDrawdownPause = isAmmDrawdownPause;
