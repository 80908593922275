"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.QuoteAssetOracleClient = exports.QUOTE_ORACLE_PRICE_DATA = void 0;
const anchor_1 = require("@coral-xyz/anchor");
const numericConstants_1 = require("../constants/numericConstants");
exports.QUOTE_ORACLE_PRICE_DATA = {
    price: numericConstants_1.PRICE_PRECISION,
    slot: new anchor_1.BN(0),
    confidence: new anchor_1.BN(1),
    hasSufficientNumberOfDataPoints: true,
};
class QuoteAssetOracleClient {
    constructor() { }
    async getOraclePriceData(_pricePublicKey) {
        return Promise.resolve(exports.QUOTE_ORACLE_PRICE_DATA);
    }
    getOraclePriceDataFromBuffer(_buffer) {
        return exports.QUOTE_ORACLE_PRICE_DATA;
    }
}
exports.QuoteAssetOracleClient = QuoteAssetOracleClient;
