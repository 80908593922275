"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getMarinadeMSolPrice = exports.getMarinadeDepositIx = exports.getMarinadeFinanceProgram = void 0;
const anchor_1 = require("@coral-xyz/anchor");
const types_1 = require("./types");
const web3_js_1 = require("@solana/web3.js");
const spl_token_1 = require("@solana/spl-token");
const marinadeFinanceProgramId = new web3_js_1.PublicKey('MarBmsSgKXdrN1egZf5sqe1TMai9K1rChYNDJgjq7aD');
function getMarinadeFinanceProgram(provider) {
    return new anchor_1.Program(types_1.IDL, marinadeFinanceProgramId, provider);
}
exports.getMarinadeFinanceProgram = getMarinadeFinanceProgram;
function getMarinadeDepositIx({ program, amount, mSOLAccount, transferFrom, }) {
    return program.methods
        .deposit(amount)
        .accountsStrict({
        reservePda: new web3_js_1.PublicKey('Du3Ysj1wKbxPKkuPPnvzQLQh8oMSVifs3jGZjJWXFmHN'),
        state: new web3_js_1.PublicKey('8szGkuLTAux9XMgZ2vtY39jVSowEcpBfFfD8hXSEqdGC'),
        msolMint: new web3_js_1.PublicKey('mSoLzYCxHdYgdzU16g5QSh3i5K3z3KZK7ytfqcJm7So'),
        msolMintAuthority: new web3_js_1.PublicKey('3JLPCS1qM2zRw3Dp6V4hZnYHd4toMNPkNesXdX9tg6KM'),
        liqPoolMsolLegAuthority: new web3_js_1.PublicKey('EyaSjUtSgo9aRD1f8LWXwdvkpDTmXAW54yoSHZRF14WL'),
        liqPoolMsolLeg: new web3_js_1.PublicKey('7GgPYjS5Dza89wV6FpZ23kUJRG5vbQ1GM25ezspYFSoE'),
        liqPoolSolLegPda: new web3_js_1.PublicKey('UefNb6z6yvArqe4cJHTXCqStRsKmWhGxnZzuHbikP5Q'),
        mintTo: mSOLAccount,
        transferFrom,
        systemProgram: web3_js_1.SystemProgram.programId,
        tokenProgram: spl_token_1.TOKEN_PROGRAM_ID,
    })
        .instruction();
}
exports.getMarinadeDepositIx = getMarinadeDepositIx;
async function getMarinadeMSolPrice(program) {
    const state = await program.account.state.fetch(new web3_js_1.PublicKey('8szGkuLTAux9XMgZ2vtY39jVSowEcpBfFfD8hXSEqdGC'));
    return state.msolPrice.toNumber() / 4294967296;
}
exports.getMarinadeMSolPrice = getMarinadeMSolPrice;
