"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WebSocketUserStatsAccountSubscriber = void 0;
const types_1 = require("./types");
const events_1 = require("events");
const webSocketAccountSubscriber_1 = require("./webSocketAccountSubscriber");
class WebSocketUserStatsAccountSubscriber {
    constructor(program, userStatsAccountPublicKey, resubOpts, commitment) {
        this.isSubscribed = false;
        this.program = program;
        this.userStatsAccountPublicKey = userStatsAccountPublicKey;
        this.eventEmitter = new events_1.EventEmitter();
        this.resubOpts = resubOpts;
        this.commitment = commitment;
    }
    async subscribe(userStatsAccount) {
        if (this.isSubscribed) {
            return true;
        }
        this.userStatsAccountSubscriber = new webSocketAccountSubscriber_1.WebSocketAccountSubscriber('userStats', this.program, this.userStatsAccountPublicKey, undefined, this.resubOpts, this.commitment);
        if (userStatsAccount) {
            this.userStatsAccountSubscriber.setData(userStatsAccount);
        }
        await this.userStatsAccountSubscriber.subscribe((data) => {
            this.eventEmitter.emit('userStatsAccountUpdate', data);
            this.eventEmitter.emit('update');
        });
        this.eventEmitter.emit('update');
        this.isSubscribed = true;
        return true;
    }
    async fetch() {
        await Promise.all([this.userStatsAccountSubscriber.fetch()]);
    }
    async unsubscribe() {
        if (!this.isSubscribed) {
            return;
        }
        await Promise.all([this.userStatsAccountSubscriber.unsubscribe()]);
        this.isSubscribed = false;
    }
    assertIsSubscribed() {
        if (!this.isSubscribed) {
            throw new types_1.NotSubscribedError('You must call `subscribe` before using this function');
        }
    }
    getUserStatsAccountAndSlot() {
        this.assertIsSubscribed();
        return this.userStatsAccountSubscriber.dataAndSlot;
    }
}
exports.WebSocketUserStatsAccountSubscriber = WebSocketUserStatsAccountSubscriber;
