"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BasicUserAccountSubscriber = void 0;
const events_1 = require("events");
/**
 * Basic implementation of UserAccountSubscriber. It will only take in UserAccount
 * data during initialization and will not fetch or subscribe to updates.
 */
class BasicUserAccountSubscriber {
    constructor(userAccountPublicKey, data, slot) {
        this.isSubscribed = true;
        this.eventEmitter = new events_1.EventEmitter();
        this.userAccountPublicKey = userAccountPublicKey;
        this.user = { data, slot };
    }
    async subscribe(_userAccount) {
        return true;
    }
    async addToAccountLoader() { }
    async fetch() { }
    doesAccountExist() {
        return this.user !== undefined;
    }
    async unsubscribe() { }
    assertIsSubscribed() { }
    getUserAccountAndSlot() {
        return this.user;
    }
    updateData(userAccount, slot) {
        var _a;
        if (!this.user || slot >= ((_a = this.user.slot) !== null && _a !== void 0 ? _a : 0)) {
            this.user = { data: userAccount, slot };
            this.eventEmitter.emit('userAccountUpdate', userAccount);
            this.eventEmitter.emit('update');
        }
    }
}
exports.BasicUserAccountSubscriber = BasicUserAccountSubscriber;
