"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getVammNodeGenerator = exports.NodeList = exports.getOrderSignature = void 0;
const __1 = require("..");
// import { PublicKey } from '@solana/web3.js';
const DLOBNode_1 = require("./DLOBNode");
function getOrderSignature(orderId, userAccount) {
    return `${userAccount.toString()}-${orderId.toString()}`;
}
exports.getOrderSignature = getOrderSignature;
class NodeList {
    constructor(nodeType, sortDirection) {
        this.nodeType = nodeType;
        this.sortDirection = sortDirection;
        this.length = 0;
        this.nodeMap = new Map();
    }
    clear() {
        this.head = undefined;
        this.length = 0;
        this.nodeMap.clear();
    }
    insert(order, marketType, userAccount) {
        if ((0, __1.isVariant)(order.status, 'init')) {
            return;
        }
        const newNode = (0, DLOBNode_1.createNode)(this.nodeType, order, userAccount);
        const orderSignature = getOrderSignature(order.orderId, userAccount);
        if (this.nodeMap.has(orderSignature)) {
            return;
        }
        this.nodeMap.set(orderSignature, newNode);
        this.length += 1;
        if (this.head === undefined) {
            this.head = newNode;
            return;
        }
        if (this.prependNode(this.head, newNode)) {
            this.head.previous = newNode;
            newNode.next = this.head;
            this.head = newNode;
            return;
        }
        let currentNode = this.head;
        while (currentNode.next !== undefined &&
            !this.prependNode(currentNode.next, newNode)) {
            currentNode = currentNode.next;
        }
        newNode.next = currentNode.next;
        if (currentNode.next !== undefined) {
            newNode.next.previous = newNode;
        }
        currentNode.next = newNode;
        newNode.previous = currentNode;
    }
    prependNode(currentNode, newNode) {
        const currentOrder = currentNode.order;
        const newOrder = newNode.order;
        const currentOrderSortPrice = currentNode.sortValue;
        const newOrderSortPrice = newNode.sortValue;
        if (newOrderSortPrice.eq(currentOrderSortPrice)) {
            return newOrder.slot.lt(currentOrder.slot);
        }
        if (this.sortDirection === 'asc') {
            return newOrderSortPrice.lt(currentOrderSortPrice);
        }
        else {
            return newOrderSortPrice.gt(currentOrderSortPrice);
        }
    }
    update(order, userAccount) {
        const orderId = getOrderSignature(order.orderId, userAccount);
        if (this.nodeMap.has(orderId)) {
            const node = this.nodeMap.get(orderId);
            Object.assign(node.order, order);
            node.haveFilled = false;
        }
    }
    remove(order, userAccount) {
        const orderId = getOrderSignature(order.orderId, userAccount);
        if (this.nodeMap.has(orderId)) {
            const node = this.nodeMap.get(orderId);
            if (node.next) {
                node.next.previous = node.previous;
            }
            if (node.previous) {
                node.previous.next = node.next;
            }
            if (this.head && node.order.orderId === this.head.order.orderId) {
                this.head = node.next;
            }
            node.previous = undefined;
            node.next = undefined;
            this.nodeMap.delete(orderId);
            this.length--;
        }
    }
    *getGenerator() {
        let node = this.head;
        while (node !== undefined) {
            yield node;
            node = node.next;
        }
    }
    has(order, userAccount) {
        return this.nodeMap.has(getOrderSignature(order.orderId, userAccount));
    }
    get(orderSignature) {
        return this.nodeMap.get(orderSignature);
    }
    print() {
        let currentNode = this.head;
        while (currentNode !== undefined) {
            console.log(currentNode.getLabel());
            currentNode = currentNode.next;
        }
    }
    printTop() {
        if (this.head) {
            console.log(this.sortDirection.toUpperCase(), this.head.getLabel());
        }
        else {
            console.log('---');
        }
    }
}
exports.NodeList = NodeList;
function* getVammNodeGenerator(price) {
    if (!price) {
        return;
    }
    yield {
        getPrice: () => price,
        isVammNode: () => true,
        order: undefined,
        userAccount: undefined,
        isBaseFilled: () => false,
        haveFilled: false,
    };
}
exports.getVammNodeGenerator = getVammNodeGenerator;
