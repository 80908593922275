"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PollingTokenAccountSubscriber = void 0;
const types_1 = require("./types");
const events_1 = require("events");
const token_1 = require("../token");
class PollingTokenAccountSubscriber {
    constructor(publicKey, accountLoader) {
        this.isSubscribed = false;
        this.publicKey = publicKey;
        this.accountLoader = accountLoader;
        this.eventEmitter = new events_1.EventEmitter();
    }
    async subscribe() {
        if (this.isSubscribed) {
            return true;
        }
        await this.addToAccountLoader();
        let subscriptionSucceeded = false;
        let retries = 0;
        while (!subscriptionSucceeded && retries < 5) {
            await this.fetch();
            subscriptionSucceeded = this.didSubscriptionSucceed();
            retries++;
        }
        if (subscriptionSucceeded) {
            this.eventEmitter.emit('update');
        }
        this.isSubscribed = subscriptionSucceeded;
        return subscriptionSucceeded;
    }
    async addToAccountLoader() {
        if (this.callbackId) {
            return;
        }
        this.callbackId = await this.accountLoader.addAccount(this.publicKey, (buffer, slot) => {
            const tokenAccount = (0, token_1.parseTokenAccount)(buffer, this.publicKey);
            this.tokenAccountAndSlot = { data: tokenAccount, slot };
            // @ts-ignore
            this.eventEmitter.emit('tokenAccountUpdate', tokenAccount);
            this.eventEmitter.emit('update');
        });
        this.errorCallbackId = this.accountLoader.addErrorCallbacks((error) => {
            this.eventEmitter.emit('error', error);
        });
    }
    async fetch() {
        await this.accountLoader.load();
        const { buffer, slot } = this.accountLoader.getBufferAndSlot(this.publicKey);
        this.tokenAccountAndSlot = {
            data: (0, token_1.parseTokenAccount)(buffer, this.publicKey),
            slot,
        };
    }
    async unsubscribe() {
        if (!this.isSubscribed) {
            return;
        }
        this.accountLoader.removeAccount(this.publicKey, this.callbackId);
        this.callbackId = undefined;
        this.accountLoader.removeErrorCallbacks(this.errorCallbackId);
        this.errorCallbackId = undefined;
        this.isSubscribed = false;
    }
    assertIsSubscribed() {
        if (!this.isSubscribed) {
            throw new types_1.NotSubscribedError('You must call `subscribe` before using this function');
        }
    }
    getTokenAccountAndSlot() {
        this.assertIsSubscribed();
        return this.tokenAccountAndSlot;
    }
    didSubscriptionSucceed() {
        return !!this.tokenAccountAndSlot;
    }
}
exports.PollingTokenAccountSubscriber = PollingTokenAccountSubscriber;
