"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TxEventCache = void 0;
class Node {
    constructor(key, value, next, prev) {
        this.key = key;
        this.value = value;
        this.next = next;
        this.prev = prev;
    }
}
// lru cache
class TxEventCache {
    constructor(maxTx = 1024) {
        this.maxTx = maxTx;
        this.size = 0;
        this.cacheMap = {};
    }
    add(key, events) {
        const existingNode = this.cacheMap[key];
        if (existingNode) {
            this.detach(existingNode);
            this.size--;
        }
        else if (this.size === this.maxTx) {
            delete this.cacheMap[this.tail.key];
            this.detach(this.tail);
            this.size--;
        }
        // Write to head of LinkedList
        if (!this.head) {
            this.head = this.tail = new Node(key, events);
        }
        else {
            const node = new Node(key, events, this.head);
            this.head.prev = node;
            this.head = node;
        }
        // update cacheMap with LinkedList key and Node reference
        this.cacheMap[key] = this.head;
        this.size++;
    }
    has(key) {
        return this.cacheMap.hasOwnProperty(key);
    }
    get(key) {
        var _a;
        return (_a = this.cacheMap[key]) === null || _a === void 0 ? void 0 : _a.value;
    }
    detach(node) {
        if (node.prev !== undefined) {
            node.prev.next = node.next;
        }
        else {
            this.head = node.next;
        }
        if (node.next !== undefined) {
            node.next.prev = node.prev;
        }
        else {
            this.tail = node.prev;
        }
    }
    clear() {
        this.head = undefined;
        this.tail = undefined;
        this.size = 0;
        this.cacheMap = {};
    }
}
exports.TxEventCache = TxEventCache;
