"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getOrderParams = exports.getMarketOrderParams = exports.getTriggerLimitOrderParams = exports.getTriggerMarketOrderParams = exports.getLimitOrderParams = void 0;
const types_1 = require("./types");
function getLimitOrderParams(params) {
    return Object.assign({}, params, {
        orderType: types_1.OrderType.LIMIT,
    });
}
exports.getLimitOrderParams = getLimitOrderParams;
function getTriggerMarketOrderParams(params) {
    return Object.assign({}, params, {
        orderType: types_1.OrderType.TRIGGER_MARKET,
    });
}
exports.getTriggerMarketOrderParams = getTriggerMarketOrderParams;
function getTriggerLimitOrderParams(params) {
    return Object.assign({}, params, {
        orderType: types_1.OrderType.TRIGGER_LIMIT,
    });
}
exports.getTriggerLimitOrderParams = getTriggerLimitOrderParams;
function getMarketOrderParams(params) {
    return Object.assign({}, params, {
        orderType: types_1.OrderType.MARKET,
    });
}
exports.getMarketOrderParams = getMarketOrderParams;
/**
 * Creates an OrderParams object with the given OptionalOrderParams and any params to override.
 *
 * example:
 * ```
 * const orderParams = getOrderParams(optionalOrderParams, { marketType: MarketType.PERP });
 * ```
 *
 * @param optionalOrderParams
 * @param overridingParams
 * @returns
 */
function getOrderParams(optionalOrderParams, overridingParams = {}) {
    return Object.assign({}, types_1.DefaultOrderParams, optionalOrderParams, overridingParams);
}
exports.getOrderParams = getOrderParams;
