"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.deriveOracleAuctionParams = exports.getAuctionPriceForOracleOffsetAuction = exports.getAuctionPriceForFixedAuction = exports.getAuctionPrice = exports.isFallbackAvailableLiquiditySource = exports.isAuctionComplete = void 0;
const types_1 = require("../types");
const _1 = require("../.");
function isAuctionComplete(order, slot) {
    if (order.auctionDuration === 0) {
        return true;
    }
    return new _1.BN(slot).sub(order.slot).gt(new _1.BN(order.auctionDuration));
}
exports.isAuctionComplete = isAuctionComplete;
function isFallbackAvailableLiquiditySource(order, minAuctionDuration, slot) {
    if (minAuctionDuration === 0) {
        return true;
    }
    return new _1.BN(slot).sub(order.slot).gt(new _1.BN(minAuctionDuration));
}
exports.isFallbackAvailableLiquiditySource = isFallbackAvailableLiquiditySource;
function getAuctionPrice(order, slot, oraclePrice) {
    if ((0, types_1.isOneOfVariant)(order.orderType, [
        'market',
        'triggerMarket',
        'limit',
        'triggerLimit',
    ])) {
        return getAuctionPriceForFixedAuction(order, slot);
    }
    else if ((0, types_1.isVariant)(order.orderType, 'oracle')) {
        return getAuctionPriceForOracleOffsetAuction(order, slot, oraclePrice);
    }
    else {
        throw Error(`Cant get auction price for order type ${(0, _1.getVariant)(order.orderType)}`);
    }
}
exports.getAuctionPrice = getAuctionPrice;
function getAuctionPriceForFixedAuction(order, slot) {
    const slotsElapsed = new _1.BN(slot).sub(order.slot);
    const deltaDenominator = new _1.BN(order.auctionDuration);
    const deltaNumerator = _1.BN.min(slotsElapsed, deltaDenominator);
    if (deltaDenominator.eq(_1.ZERO)) {
        return order.auctionEndPrice;
    }
    let priceDelta;
    if ((0, types_1.isVariant)(order.direction, 'long')) {
        priceDelta = order.auctionEndPrice
            .sub(order.auctionStartPrice)
            .mul(deltaNumerator)
            .div(deltaDenominator);
    }
    else {
        priceDelta = order.auctionStartPrice
            .sub(order.auctionEndPrice)
            .mul(deltaNumerator)
            .div(deltaDenominator);
    }
    let price;
    if ((0, types_1.isVariant)(order.direction, 'long')) {
        price = order.auctionStartPrice.add(priceDelta);
    }
    else {
        price = order.auctionStartPrice.sub(priceDelta);
    }
    return price;
}
exports.getAuctionPriceForFixedAuction = getAuctionPriceForFixedAuction;
function getAuctionPriceForOracleOffsetAuction(order, slot, oraclePrice) {
    const slotsElapsed = new _1.BN(slot).sub(order.slot);
    const deltaDenominator = new _1.BN(order.auctionDuration);
    const deltaNumerator = _1.BN.min(slotsElapsed, deltaDenominator);
    if (deltaDenominator.eq(_1.ZERO)) {
        return _1.BN.max(oraclePrice.add(order.auctionEndPrice), _1.ONE);
    }
    let priceOffsetDelta;
    if ((0, types_1.isVariant)(order.direction, 'long')) {
        priceOffsetDelta = order.auctionEndPrice
            .sub(order.auctionStartPrice)
            .mul(deltaNumerator)
            .div(deltaDenominator);
    }
    else {
        priceOffsetDelta = order.auctionStartPrice
            .sub(order.auctionEndPrice)
            .mul(deltaNumerator)
            .div(deltaDenominator);
    }
    let priceOffset;
    if ((0, types_1.isVariant)(order.direction, 'long')) {
        priceOffset = order.auctionStartPrice.add(priceOffsetDelta);
    }
    else {
        priceOffset = order.auctionStartPrice.sub(priceOffsetDelta);
    }
    return _1.BN.max(oraclePrice.add(priceOffset), _1.ONE);
}
exports.getAuctionPriceForOracleOffsetAuction = getAuctionPriceForOracleOffsetAuction;
function deriveOracleAuctionParams({ direction, oraclePrice, auctionStartPrice, auctionEndPrice, limitPrice, auctionPriceCaps, }) {
    let oraclePriceOffset;
    if (limitPrice.eq(_1.ZERO) || oraclePrice.eq(_1.ZERO)) {
        oraclePriceOffset = _1.ZERO;
    }
    else {
        oraclePriceOffset = limitPrice.sub(oraclePrice);
    }
    if (oraclePriceOffset.eq(_1.ZERO)) {
        oraclePriceOffset = (0, types_1.isVariant)(direction, 'long')
            ? auctionEndPrice.sub(oraclePrice).add(_1.ONE)
            : auctionEndPrice.sub(oraclePrice).sub(_1.ONE);
    }
    let oraclePriceOffsetNum;
    try {
        oraclePriceOffsetNum = oraclePriceOffset.toNumber();
    }
    catch (e) {
        oraclePriceOffsetNum = 0;
    }
    if (auctionPriceCaps) {
        auctionStartPrice = _1.BN.min(_1.BN.max(auctionStartPrice, auctionPriceCaps.min), auctionPriceCaps.max);
        auctionEndPrice = _1.BN.min(_1.BN.max(auctionEndPrice, auctionPriceCaps.min), auctionPriceCaps.max);
    }
    return {
        auctionStartPrice: auctionStartPrice.sub(oraclePrice),
        auctionEndPrice: auctionEndPrice.sub(oraclePrice),
        oraclePriceOffset: oraclePriceOffsetNum,
    };
}
exports.deriveOracleAuctionParams = deriveOracleAuctionParams;
