"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuctionSubscriber = void 0;
const memcmp_1 = require("../memcmp");
const events_1 = require("events");
const webSocketProgramAccountSubscriber_1 = require("../accounts/webSocketProgramAccountSubscriber");
class AuctionSubscriber {
    constructor({ driftClient, opts, resubTimeoutMs, logResubMessages, }) {
        this.driftClient = driftClient;
        this.opts = opts || this.driftClient.opts;
        this.eventEmitter = new events_1.EventEmitter();
        this.resubOpts = { resubTimeoutMs, logResubMessages };
    }
    async subscribe() {
        if (!this.subscriber) {
            this.subscriber = new webSocketProgramAccountSubscriber_1.WebSocketProgramAccountSubscriber('AuctionSubscriber', 'User', this.driftClient.program, this.driftClient.program.account.user.coder.accounts.decode.bind(this.driftClient.program.account.user.coder.accounts), {
                filters: [(0, memcmp_1.getUserFilter)(), (0, memcmp_1.getUserWithAuctionFilter)()],
                commitment: this.opts.commitment,
            }, this.resubOpts);
        }
        await this.subscriber.subscribe((accountId, data, context) => {
            this.eventEmitter.emit('onAccountUpdate', data, accountId, context.slot);
        });
    }
    async unsubscribe() {
        if (!this.subscriber) {
            return;
        }
        this.subscriber.unsubscribe();
    }
}
exports.AuctionSubscriber = AuctionSubscriber;
