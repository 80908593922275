"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TestClient = void 0;
const adminClient_1 = require("./adminClient");
class TestClient extends adminClient_1.AdminClient {
    constructor(config) {
        if (config.accountSubscription.type !== 'polling') {
            throw new Error('Test client must be polling');
        }
        super(config);
    }
    async sendTransaction(tx, additionalSigners, opts, preSigned) {
        const { txSig, slot } = await super.sendTransaction(tx, additionalSigners, opts, preSigned);
        let lastFetchedSlot = this.accountSubscriber.accountLoader.mostRecentSlot;
        await this.fetchAccounts();
        while (lastFetchedSlot < slot) {
            await this.fetchAccounts();
            lastFetchedSlot = this.accountSubscriber.accountLoader.mostRecentSlot;
        }
        return { txSig, slot };
    }
}
exports.TestClient = TestClient;
