"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AverageStrategy = void 0;
class AverageStrategy {
    calculate(samples) {
        return (samples.reduce((a, b) => {
            return a + b.prioritizationFee;
        }, 0) / samples.length);
    }
}
exports.AverageStrategy = AverageStrategy;
