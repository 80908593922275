"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RetryTxSender = void 0;
const types_1 = require("./types");
const anchor_1 = require("@coral-xyz/anchor");
const baseTxSender_1 = require("./baseTxSender");
const DEFAULT_TIMEOUT = 35000;
const DEFAULT_RETRY = 2000;
class RetryTxSender extends baseTxSender_1.BaseTxSender {
    constructor({ connection, wallet, opts = { ...anchor_1.AnchorProvider.defaultOptions(), maxRetries: 0 }, timeout = DEFAULT_TIMEOUT, retrySleep = DEFAULT_RETRY, additionalConnections = new Array(), confirmationStrategy = types_1.ConfirmationStrategy.Combo, additionalTxSenderCallbacks = [], txHandler, trackTxLandRate, txLandRateLookbackWindowMinutes, landRateToFeeFunc, }) {
        super({
            connection,
            wallet,
            opts,
            timeout,
            additionalConnections,
            confirmationStrategy,
            additionalTxSenderCallbacks,
            txHandler,
            trackTxLandRate,
            txLandRateLookbackWindowMinutes,
            landRateToFeeFunc,
        });
        this.timoutCount = 0;
        this.connection = connection;
        this.wallet = wallet;
        this.opts = opts;
        this.timeout = timeout;
        this.retrySleep = retrySleep;
        this.additionalConnections = additionalConnections;
    }
    async sleep(reference) {
        return new Promise((resolve) => {
            reference.resolve = resolve;
            setTimeout(resolve, this.retrySleep);
        });
    }
    async sendRawTransaction(rawTransaction, opts) {
        var _a, _b;
        const startTime = this.getTimestamp();
        const txid = await this.connection.sendRawTransaction(rawTransaction, opts);
        (_a = this.txSigCache) === null || _a === void 0 ? void 0 : _a.set(txid, false);
        this.sendToAdditionalConnections(rawTransaction, opts);
        let done = false;
        const resolveReference = {
            resolve: undefined,
        };
        const stopWaiting = () => {
            done = true;
            if (resolveReference.resolve) {
                resolveReference.resolve();
            }
        };
        (async () => {
            while (!done && this.getTimestamp() - startTime < this.timeout) {
                await this.sleep(resolveReference);
                if (!done) {
                    this.connection
                        .sendRawTransaction(rawTransaction, opts)
                        .catch((e) => {
                        console.error(e);
                        stopWaiting();
                    });
                    this.sendToAdditionalConnections(rawTransaction, opts);
                }
            }
        })();
        let slot;
        try {
            const result = await this.confirmTransaction(txid, opts.commitment);
            (_b = this.txSigCache) === null || _b === void 0 ? void 0 : _b.set(txid, true);
            await this.checkConfirmationResultForError(txid, result.value);
            slot = result.context.slot;
            // eslint-disable-next-line no-useless-catch
        }
        catch (e) {
            throw e;
        }
        finally {
            stopWaiting();
        }
        return { txSig: txid, slot };
    }
}
exports.RetryTxSender = RetryTxSender;
