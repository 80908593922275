"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChainClock = void 0;
class ChainClock {
    constructor(props) {
        this._state = new Map();
        props.forEach((prop) => {
            this._state.set(prop.commitment, prop);
        });
    }
    update(props) {
        const state = this._state.get(props.commitment);
        if (state) {
            if (props.blockHeight)
                state.blockHeight = props.blockHeight;
            if (props.slot)
                state.slot = props.slot;
            if (props.ts)
                state.ts = props.ts;
        }
        else {
            this._state.set(props.commitment, props);
        }
    }
    getState(commitment) {
        return this._state.get(commitment);
    }
}
exports.ChainClock = ChainClock;
