"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getTokenProgramForSpotMarket = exports.getPythPullOraclePublicKey = exports.getPrelaunchOraclePublicKey = exports.getProtocolIfSharesTransferConfigPublicKey = exports.getReferrerNamePublicKeySync = exports.getOpenbookV2FulfillmentConfigPublicKey = exports.getPhoenixFulfillmentConfigPublicKey = exports.getSerumFulfillmentConfigPublicKey = exports.getSerumSignerPublicKey = exports.getSerumOpenOrdersPublicKey = exports.getDriftSignerPublicKey = exports.getInsuranceFundStakeAccountPublicKey = exports.getInsuranceFundVaultPublicKey = exports.getSpotMarketVaultPublicKey = exports.getSpotMarketPublicKeySync = exports.getSpotMarketPublicKey = exports.getPerpMarketPublicKeySync = exports.getPerpMarketPublicKey = exports.getUserStatsAccountPublicKey = exports.getUserAccountPublicKeySync = exports.getUserAccountPublicKey = exports.getUserAccountPublicKeyAndNonce = exports.getDriftStateAccountPublicKey = exports.getDriftStateAccountPublicKeyAndNonce = void 0;
const web3_js_1 = require("@solana/web3.js");
const anchor = __importStar(require("@coral-xyz/anchor"));
const spl_token_1 = require("@solana/spl-token");
async function getDriftStateAccountPublicKeyAndNonce(programId) {
    return web3_js_1.PublicKey.findProgramAddress([Buffer.from(anchor.utils.bytes.utf8.encode('drift_state'))], programId);
}
exports.getDriftStateAccountPublicKeyAndNonce = getDriftStateAccountPublicKeyAndNonce;
async function getDriftStateAccountPublicKey(programId) {
    return (await getDriftStateAccountPublicKeyAndNonce(programId))[0];
}
exports.getDriftStateAccountPublicKey = getDriftStateAccountPublicKey;
async function getUserAccountPublicKeyAndNonce(programId, authority, subAccountId = 0) {
    return web3_js_1.PublicKey.findProgramAddress([
        Buffer.from(anchor.utils.bytes.utf8.encode('user')),
        authority.toBuffer(),
        new anchor.BN(subAccountId).toArrayLike(Buffer, 'le', 2),
    ], programId);
}
exports.getUserAccountPublicKeyAndNonce = getUserAccountPublicKeyAndNonce;
async function getUserAccountPublicKey(programId, authority, subAccountId = 0) {
    return (await getUserAccountPublicKeyAndNonce(programId, authority, subAccountId))[0];
}
exports.getUserAccountPublicKey = getUserAccountPublicKey;
function getUserAccountPublicKeySync(programId, authority, subAccountId = 0) {
    return web3_js_1.PublicKey.findProgramAddressSync([
        Buffer.from(anchor.utils.bytes.utf8.encode('user')),
        authority.toBuffer(),
        new anchor.BN(subAccountId).toArrayLike(Buffer, 'le', 2),
    ], programId)[0];
}
exports.getUserAccountPublicKeySync = getUserAccountPublicKeySync;
function getUserStatsAccountPublicKey(programId, authority) {
    return web3_js_1.PublicKey.findProgramAddressSync([
        Buffer.from(anchor.utils.bytes.utf8.encode('user_stats')),
        authority.toBuffer(),
    ], programId)[0];
}
exports.getUserStatsAccountPublicKey = getUserStatsAccountPublicKey;
async function getPerpMarketPublicKey(programId, marketIndex) {
    return (await web3_js_1.PublicKey.findProgramAddress([
        Buffer.from(anchor.utils.bytes.utf8.encode('perp_market')),
        new anchor.BN(marketIndex).toArrayLike(Buffer, 'le', 2),
    ], programId))[0];
}
exports.getPerpMarketPublicKey = getPerpMarketPublicKey;
function getPerpMarketPublicKeySync(programId, marketIndex) {
    return web3_js_1.PublicKey.findProgramAddressSync([
        Buffer.from(anchor.utils.bytes.utf8.encode('perp_market')),
        new anchor.BN(marketIndex).toArrayLike(Buffer, 'le', 2),
    ], programId)[0];
}
exports.getPerpMarketPublicKeySync = getPerpMarketPublicKeySync;
async function getSpotMarketPublicKey(programId, marketIndex) {
    return (await web3_js_1.PublicKey.findProgramAddress([
        Buffer.from(anchor.utils.bytes.utf8.encode('spot_market')),
        new anchor.BN(marketIndex).toArrayLike(Buffer, 'le', 2),
    ], programId))[0];
}
exports.getSpotMarketPublicKey = getSpotMarketPublicKey;
function getSpotMarketPublicKeySync(programId, marketIndex) {
    return web3_js_1.PublicKey.findProgramAddressSync([
        Buffer.from(anchor.utils.bytes.utf8.encode('spot_market')),
        new anchor.BN(marketIndex).toArrayLike(Buffer, 'le', 2),
    ], programId)[0];
}
exports.getSpotMarketPublicKeySync = getSpotMarketPublicKeySync;
async function getSpotMarketVaultPublicKey(programId, marketIndex) {
    return (await web3_js_1.PublicKey.findProgramAddress([
        Buffer.from(anchor.utils.bytes.utf8.encode('spot_market_vault')),
        new anchor.BN(marketIndex).toArrayLike(Buffer, 'le', 2),
    ], programId))[0];
}
exports.getSpotMarketVaultPublicKey = getSpotMarketVaultPublicKey;
async function getInsuranceFundVaultPublicKey(programId, marketIndex) {
    return (await web3_js_1.PublicKey.findProgramAddress([
        Buffer.from(anchor.utils.bytes.utf8.encode('insurance_fund_vault')),
        new anchor.BN(marketIndex).toArrayLike(Buffer, 'le', 2),
    ], programId))[0];
}
exports.getInsuranceFundVaultPublicKey = getInsuranceFundVaultPublicKey;
function getInsuranceFundStakeAccountPublicKey(programId, authority, marketIndex) {
    return web3_js_1.PublicKey.findProgramAddressSync([
        Buffer.from(anchor.utils.bytes.utf8.encode('insurance_fund_stake')),
        authority.toBuffer(),
        new anchor.BN(marketIndex).toArrayLike(Buffer, 'le', 2),
    ], programId)[0];
}
exports.getInsuranceFundStakeAccountPublicKey = getInsuranceFundStakeAccountPublicKey;
function getDriftSignerPublicKey(programId) {
    return web3_js_1.PublicKey.findProgramAddressSync([Buffer.from(anchor.utils.bytes.utf8.encode('drift_signer'))], programId)[0];
}
exports.getDriftSignerPublicKey = getDriftSignerPublicKey;
function getSerumOpenOrdersPublicKey(programId, market) {
    return web3_js_1.PublicKey.findProgramAddressSync([
        Buffer.from(anchor.utils.bytes.utf8.encode('serum_open_orders')),
        market.toBuffer(),
    ], programId)[0];
}
exports.getSerumOpenOrdersPublicKey = getSerumOpenOrdersPublicKey;
function getSerumSignerPublicKey(programId, market, nonce) {
    return anchor.web3.PublicKey.createProgramAddressSync([market.toBuffer(), nonce.toArrayLike(Buffer, 'le', 8)], programId);
}
exports.getSerumSignerPublicKey = getSerumSignerPublicKey;
function getSerumFulfillmentConfigPublicKey(programId, market) {
    return web3_js_1.PublicKey.findProgramAddressSync([
        Buffer.from(anchor.utils.bytes.utf8.encode('serum_fulfillment_config')),
        market.toBuffer(),
    ], programId)[0];
}
exports.getSerumFulfillmentConfigPublicKey = getSerumFulfillmentConfigPublicKey;
function getPhoenixFulfillmentConfigPublicKey(programId, market) {
    return web3_js_1.PublicKey.findProgramAddressSync([
        Buffer.from(anchor.utils.bytes.utf8.encode('phoenix_fulfillment_config')),
        market.toBuffer(),
    ], programId)[0];
}
exports.getPhoenixFulfillmentConfigPublicKey = getPhoenixFulfillmentConfigPublicKey;
function getOpenbookV2FulfillmentConfigPublicKey(programId, market) {
    return web3_js_1.PublicKey.findProgramAddressSync([
        Buffer.from(anchor.utils.bytes.utf8.encode('openbook_v2_fulfillment_config')),
        market.toBuffer(),
    ], programId)[0];
}
exports.getOpenbookV2FulfillmentConfigPublicKey = getOpenbookV2FulfillmentConfigPublicKey;
function getReferrerNamePublicKeySync(programId, nameBuffer) {
    return web3_js_1.PublicKey.findProgramAddressSync([
        Buffer.from(anchor.utils.bytes.utf8.encode('referrer_name')),
        Buffer.from(nameBuffer),
    ], programId)[0];
}
exports.getReferrerNamePublicKeySync = getReferrerNamePublicKeySync;
function getProtocolIfSharesTransferConfigPublicKey(programId) {
    return web3_js_1.PublicKey.findProgramAddressSync([Buffer.from(anchor.utils.bytes.utf8.encode('if_shares_transfer_config'))], programId)[0];
}
exports.getProtocolIfSharesTransferConfigPublicKey = getProtocolIfSharesTransferConfigPublicKey;
function getPrelaunchOraclePublicKey(programId, marketIndex) {
    return web3_js_1.PublicKey.findProgramAddressSync([
        Buffer.from(anchor.utils.bytes.utf8.encode('prelaunch_oracle')),
        new anchor.BN(marketIndex).toArrayLike(Buffer, 'le', 2),
    ], programId)[0];
}
exports.getPrelaunchOraclePublicKey = getPrelaunchOraclePublicKey;
function getPythPullOraclePublicKey(progarmId, feedId) {
    return web3_js_1.PublicKey.findProgramAddressSync([
        Buffer.from(anchor.utils.bytes.utf8.encode('pyth_pull')),
        Buffer.from(feedId),
    ], progarmId)[0];
}
exports.getPythPullOraclePublicKey = getPythPullOraclePublicKey;
function getTokenProgramForSpotMarket(spotMarketAccount) {
    if (spotMarketAccount.tokenProgram === 1) {
        return spl_token_1.TOKEN_2022_PROGRAM_ID;
    }
    return spl_token_1.TOKEN_PROGRAM_ID;
}
exports.getTokenProgramForSpotMarket = getTokenProgramForSpotMarket;
