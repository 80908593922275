"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.unstakeSharesToAmountWithOpenRequest = exports.unstakeSharesToAmount = exports.stakeAmountToShares = exports.nextRevenuePoolSettleApr = void 0;
const numericConstants_1 = require("../constants/numericConstants");
const index_1 = require("../index");
const spotBalance_1 = require("../math/spotBalance");
function nextRevenuePoolSettleApr(spotMarket, vaultBalance, // vault token amount
amount // delta token amount
) {
    const MAX_APR = new index_1.BN(10).mul(numericConstants_1.PERCENTAGE_PRECISION); // 1000% APR
    // Conmputing the APR:
    const revenuePoolBN = (0, spotBalance_1.getTokenAmount)(spotMarket.revenuePool.scaledBalance, spotMarket, index_1.SpotBalanceType.DEPOSIT);
    const payoutRatio = 0.1;
    const ratioForStakers = spotMarket.insuranceFund.totalFactor > 0 &&
        spotMarket.insuranceFund.userFactor > 0 &&
        spotMarket.insuranceFund.revenueSettlePeriod.gt(numericConstants_1.ZERO)
        ? spotMarket.insuranceFund.userFactor /
            spotMarket.insuranceFund.totalFactor
        : 0;
    // Settle periods from on-chain data:
    const revSettlePeriod = spotMarket.insuranceFund.revenueSettlePeriod.toNumber() * 1000;
    const settlesPerYear = 31536000000 / revSettlePeriod;
    const projectedAnnualRev = revenuePoolBN
        .muln(settlesPerYear)
        .muln(payoutRatio);
    const uncappedApr = vaultBalance.add(amount).eq(numericConstants_1.ZERO)
        ? 0
        : projectedAnnualRev.muln(1000).div(vaultBalance.add(amount)).toNumber() *
            100 *
            1000;
    const cappedApr = Math.min(uncappedApr, MAX_APR.toNumber());
    const nextApr = cappedApr * ratioForStakers;
    return nextApr;
}
exports.nextRevenuePoolSettleApr = nextRevenuePoolSettleApr;
function stakeAmountToShares(amount, totalIfShares, insuranceFundVaultBalance) {
    let nShares;
    if (insuranceFundVaultBalance.gt(numericConstants_1.ZERO)) {
        nShares = amount.mul(totalIfShares).div(insuranceFundVaultBalance);
    }
    else {
        nShares = amount;
    }
    return nShares;
}
exports.stakeAmountToShares = stakeAmountToShares;
function unstakeSharesToAmount(nShares, totalIfShares, insuranceFundVaultBalance) {
    let amount;
    if (totalIfShares.gt(numericConstants_1.ZERO)) {
        amount = index_1.BN.max(numericConstants_1.ZERO, nShares.mul(insuranceFundVaultBalance).div(totalIfShares));
    }
    else {
        amount = numericConstants_1.ZERO;
    }
    return amount;
}
exports.unstakeSharesToAmount = unstakeSharesToAmount;
function unstakeSharesToAmountWithOpenRequest(nShares, withdrawRequestShares, withdrawRequestAmount, totalIfShares, insuranceFundVaultBalance) {
    let stakedAmount;
    if (totalIfShares.gt(numericConstants_1.ZERO)) {
        stakedAmount = index_1.BN.max(numericConstants_1.ZERO, nShares
            .sub(withdrawRequestShares)
            .mul(insuranceFundVaultBalance)
            .div(totalIfShares));
    }
    else {
        stakedAmount = numericConstants_1.ZERO;
    }
    const withdrawAmount = index_1.BN.min(withdrawRequestAmount, withdrawRequestShares.mul(insuranceFundVaultBalance).div(totalIfShares));
    const amount = withdrawAmount.add(stakedAmount);
    return amount;
}
exports.unstakeSharesToAmountWithOpenRequest = unstakeSharesToAmountWithOpenRequest;
