"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getFeedIdUint8Array = exports.trimFeedId = void 0;
function trimFeedId(feedId) {
    if (feedId.startsWith('0x')) {
        return feedId.slice(2);
    }
    return feedId;
}
exports.trimFeedId = trimFeedId;
function getFeedIdUint8Array(feedId) {
    const trimmedFeedId = trimFeedId(feedId);
    return Uint8Array.from(Buffer.from(trimmedFeedId, 'hex'));
}
exports.getFeedIdUint8Array = getFeedIdUint8Array;
