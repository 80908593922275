"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LogParser = exports.fetchTransactionLogs = exports.fetchLogs = void 0;
const promiseTimeout_1 = require("../util/promiseTimeout");
const parse_1 = require("./parse");
function mapTransactionResponseToLog(transaction) {
    return {
        txSig: transaction.transaction.signatures[0],
        slot: transaction.slot,
        logs: transaction.meta.logMessages,
    };
}
async function fetchLogs(connection, address, finality, beforeTx, untilTx, limit, batchSize = 25) {
    const signatures = await connection.getSignaturesForAddress(address, {
        before: beforeTx,
        until: untilTx,
        limit,
    }, finality);
    const sortedSignatures = signatures.sort((a, b) => a.slot === b.slot ? 0 : a.slot < b.slot ? -1 : 1);
    const filteredSignatures = sortedSignatures.filter((signature) => !signature.err);
    if (filteredSignatures.length === 0) {
        return undefined;
    }
    const chunkedSignatures = chunk(filteredSignatures, batchSize);
    const transactionLogs = (await Promise.all(chunkedSignatures.map(async (chunk) => {
        return await fetchTransactionLogs(connection, chunk.map((confirmedSignature) => confirmedSignature.signature), finality);
    }))).flat();
    const earliest = filteredSignatures[0];
    const mostRecent = filteredSignatures[filteredSignatures.length - 1];
    return {
        transactionLogs: transactionLogs,
        earliestTx: earliest.signature,
        mostRecentTx: mostRecent.signature,
        earliestSlot: earliest.slot,
        mostRecentSlot: mostRecent.slot,
        mostRecentBlockTime: mostRecent.blockTime,
    };
}
exports.fetchLogs = fetchLogs;
async function fetchTransactionLogs(connection, signatures, finality) {
    const requests = new Array();
    for (const signature of signatures) {
        const args = [
            signature,
            { commitment: finality, maxSupportedTransactionVersion: 0 },
        ];
        requests.push({
            methodName: 'getTransaction',
            args,
        });
    }
    const rpcResponses = await (0, promiseTimeout_1.promiseTimeout)(
    // @ts-ignore
    connection._rpcBatchRequest(requests), 10 * 1000 // 10 second timeout
    );
    if (rpcResponses === null) {
        return Promise.reject('RPC request timed out fetching transactions');
    }
    const logs = new Array();
    for (const rpcResponse of rpcResponses) {
        if (rpcResponse.result) {
            logs.push(mapTransactionResponseToLog(rpcResponse.result));
        }
    }
    return logs;
}
exports.fetchTransactionLogs = fetchTransactionLogs;
function chunk(array, size) {
    return new Array(Math.ceil(array.length / size))
        .fill(null)
        .map((_, index) => index * size)
        .map((begin) => array.slice(begin, begin + size));
}
class LogParser {
    constructor(program) {
        this.program = program;
    }
    parseEventsFromTransaction(transaction) {
        const transactionLogObject = mapTransactionResponseToLog(transaction);
        return this.parseEventsFromLogs(transactionLogObject);
    }
    parseEventsFromLogs(event) {
        const records = [];
        if (!event.logs)
            return records;
        let runningEventIndex = 0;
        for (const eventLog of (0, parse_1.parseLogs)(this.program, event.logs)) {
            eventLog.data.txSig = event.txSig;
            eventLog.data.slot = event.slot;
            eventLog.data.eventType = eventLog.name;
            eventLog.data.txSigIndex = runningEventIndex;
            // @ts-ignore
            records.push(eventLog.data);
            runningEventIndex++;
        }
        return records;
    }
}
exports.LogParser = LogParser;
