"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PriorityFeeSubscriber = void 0;
const types_1 = require("./types");
const averageOverSlotsStrategy_1 = require("./averageOverSlotsStrategy");
const maxOverSlotsStrategy_1 = require("./maxOverSlotsStrategy");
const solanaPriorityFeeMethod_1 = require("./solanaPriorityFeeMethod");
const heliusPriorityFeeMethod_1 = require("./heliusPriorityFeeMethod");
const driftPriorityFeeMethod_1 = require("./driftPriorityFeeMethod");
class PriorityFeeSubscriber {
    constructor(config) {
        var _a, _b, _c;
        this.averageStrategy = new averageOverSlotsStrategy_1.AverageOverSlotsStrategy();
        this.maxStrategy = new maxOverSlotsStrategy_1.MaxOverSlotsStrategy();
        this.priorityFeeMethod = types_1.PriorityFeeMethod.SOLANA;
        this.latestPriorityFee = 0;
        this.lastCustomStrategyResult = 0;
        this.lastAvgStrategyResult = 0;
        this.lastMaxStrategyResult = 0;
        this.lastSlotSeen = 0;
        this.connection = config.connection;
        this.frequencyMs =
            (_a = config.frequencyMs) !== null && _a !== void 0 ? _a : types_1.DEFAULT_PRIORITY_FEE_MAP_FREQUENCY_MS;
        this.addresses = config.addresses
            ? config.addresses.map((address) => address.toBase58())
            : [];
        this.driftMarkets = config.driftMarkets;
        if (config.customStrategy) {
            this.customStrategy = config.customStrategy;
        }
        else {
            this.customStrategy = this.averageStrategy;
        }
        this.lookbackDistance = (_b = config.slotsToCheck) !== null && _b !== void 0 ? _b : 50;
        if (config.priorityFeeMethod) {
            this.priorityFeeMethod = config.priorityFeeMethod;
            if (this.priorityFeeMethod === types_1.PriorityFeeMethod.HELIUS) {
                if (config.heliusRpcUrl === undefined) {
                    if (this.connection.rpcEndpoint.includes('helius')) {
                        this.heliusRpcUrl = this.connection.rpcEndpoint;
                    }
                    else {
                        throw new Error('Connection must be helius, or heliusRpcUrl must be provided to use PriorityFeeMethod.HELIUS');
                    }
                }
                else {
                    this.heliusRpcUrl = config.heliusRpcUrl;
                }
            }
            else if (this.priorityFeeMethod === types_1.PriorityFeeMethod.DRIFT) {
                this.driftPriorityFeeEndpoint = config.driftPriorityFeeEndpoint;
            }
        }
        if (this.priorityFeeMethod === types_1.PriorityFeeMethod.SOLANA) {
            if (this.connection === undefined) {
                throw new Error('connection must be provided to use SOLANA priority fee API');
            }
        }
        this.maxFeeMicroLamports = config.maxFeeMicroLamports;
        this.priorityFeeMultiplier = (_c = config.priorityFeeMultiplier) !== null && _c !== void 0 ? _c : 1.0;
    }
    async subscribe() {
        if (this.intervalId) {
            return;
        }
        this.intervalId = setInterval(this.load.bind(this), this.frequencyMs); // we set the intervalId first, preventing a side effect of unsubscribing failing during the race condition where unsubscribes happens before subscribe is finished
        await this.load();
    }
    async loadForSolana() {
        const samples = await (0, solanaPriorityFeeMethod_1.fetchSolanaPriorityFee)(this.connection, this.lookbackDistance, this.addresses);
        if (samples.length > 0) {
            this.latestPriorityFee = samples[0].prioritizationFee;
            this.lastSlotSeen = samples[0].slot;
            this.lastAvgStrategyResult = this.averageStrategy.calculate(samples);
            this.lastMaxStrategyResult = this.maxStrategy.calculate(samples);
            if (this.customStrategy) {
                this.lastCustomStrategyResult = this.customStrategy.calculate(samples);
            }
        }
    }
    async loadForHelius() {
        var _a, _b;
        const sample = await (0, heliusPriorityFeeMethod_1.fetchHeliusPriorityFee)(this.heliusRpcUrl, this.lookbackDistance, this.addresses);
        this.lastHeliusSample = (_b = (_a = sample === null || sample === void 0 ? void 0 : sample.result) === null || _a === void 0 ? void 0 : _a.priorityFeeLevels) !== null && _b !== void 0 ? _b : undefined;
        if (this.lastHeliusSample) {
            this.lastAvgStrategyResult =
                this.lastHeliusSample[heliusPriorityFeeMethod_1.HeliusPriorityLevel.MEDIUM];
            this.lastMaxStrategyResult =
                this.lastHeliusSample[heliusPriorityFeeMethod_1.HeliusPriorityLevel.UNSAFE_MAX];
            if (this.customStrategy) {
                this.lastCustomStrategyResult = this.customStrategy.calculate(sample);
            }
        }
    }
    async loadForDrift() {
        if (!this.driftMarkets) {
            return;
        }
        const sample = await (0, driftPriorityFeeMethod_1.fetchDriftPriorityFee)(this.driftPriorityFeeEndpoint, this.driftMarkets.map((m) => m.marketType), this.driftMarkets.map((m) => m.marketIndex));
        if (sample.length > 0) {
            this.lastAvgStrategyResult = sample[heliusPriorityFeeMethod_1.HeliusPriorityLevel.MEDIUM];
            this.lastMaxStrategyResult = sample[heliusPriorityFeeMethod_1.HeliusPriorityLevel.UNSAFE_MAX];
            if (this.customStrategy) {
                this.lastCustomStrategyResult = this.customStrategy.calculate(sample);
            }
        }
    }
    getMaxPriorityFee() {
        return this.maxFeeMicroLamports;
    }
    updateMaxPriorityFee(newMaxFee) {
        this.maxFeeMicroLamports = newMaxFee;
    }
    getPriorityFeeMultiplier() {
        var _a;
        return (_a = this.priorityFeeMultiplier) !== null && _a !== void 0 ? _a : 1.0;
    }
    updatePriorityFeeMultiplier(newPriorityFeeMultiplier) {
        this.priorityFeeMultiplier = newPriorityFeeMultiplier;
    }
    updateCustomStrategy(newStrategy) {
        this.customStrategy = newStrategy;
    }
    getHeliusPriorityFeeLevel(level = heliusPriorityFeeMethod_1.HeliusPriorityLevel.MEDIUM) {
        if (this.lastHeliusSample === undefined) {
            return 0;
        }
        if (this.maxFeeMicroLamports !== undefined) {
            return Math.min(this.maxFeeMicroLamports, this.lastHeliusSample[level]);
        }
        return this.lastHeliusSample[level];
    }
    getCustomStrategyResult() {
        const result = this.lastCustomStrategyResult * this.getPriorityFeeMultiplier();
        if (this.maxFeeMicroLamports !== undefined) {
            return Math.min(this.maxFeeMicroLamports, result);
        }
        return result;
    }
    getAvgStrategyResult() {
        const result = this.lastAvgStrategyResult * this.getPriorityFeeMultiplier();
        if (this.maxFeeMicroLamports !== undefined) {
            return Math.min(this.maxFeeMicroLamports, result);
        }
        return result;
    }
    getMaxStrategyResult() {
        const result = this.lastMaxStrategyResult * this.getPriorityFeeMultiplier();
        if (this.maxFeeMicroLamports !== undefined) {
            return Math.min(this.maxFeeMicroLamports, result);
        }
        return result;
    }
    async load() {
        try {
            if (this.priorityFeeMethod === types_1.PriorityFeeMethod.SOLANA) {
                await this.loadForSolana();
            }
            else if (this.priorityFeeMethod === types_1.PriorityFeeMethod.HELIUS) {
                await this.loadForHelius();
            }
            else if (this.priorityFeeMethod === types_1.PriorityFeeMethod.DRIFT) {
                await this.loadForDrift();
            }
            else {
                throw new Error(`${this.priorityFeeMethod} load not implemented`);
            }
        }
        catch (err) {
            const e = err;
            console.error(`Error loading priority fee ${this.priorityFeeMethod}: ${e.message}\n${e.stack ? e.stack : ''}`);
            return;
        }
    }
    async unsubscribe() {
        if (this.intervalId) {
            clearInterval(this.intervalId);
            this.intervalId = undefined;
        }
    }
    updateAddresses(addresses) {
        this.addresses = addresses.map((k) => k.toBase58());
    }
    updateMarketTypeAndIndex(driftMarkets) {
        this.driftMarkets = driftMarkets;
    }
}
exports.PriorityFeeSubscriber = PriorityFeeSubscriber;
