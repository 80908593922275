"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CachedBlockhashFetcher = void 0;
/**
 * Fetches the latest blockhash and caches it for a configurable amount of time.
 *
 * - Prevents RPC spam by reusing cached values
 * - Retries on failure with exponential backoff
 * - Prevents concurrent requests for the same blockhash
 */
class CachedBlockhashFetcher {
    constructor(connection, blockhashCommitment, retryCount, retrySleepTimeMs, staleCacheTimeMs) {
        this.connection = connection;
        this.blockhashCommitment = blockhashCommitment;
        this.retryCount = retryCount;
        this.retrySleepTimeMs = retrySleepTimeMs;
        this.staleCacheTimeMs = staleCacheTimeMs;
        this.recentBlockhashCache = { value: undefined, lastUpdated: 0 };
        this.blockhashFetchingPromise = null;
    }
    async fetchBlockhashWithRetry() {
        for (let i = 0; i < this.retryCount; i++) {
            try {
                return await this.connection.getLatestBlockhash(this.blockhashCommitment);
            }
            catch (err) {
                if (i === this.retryCount - 1) {
                    throw new Error('Failed to fetch blockhash after maximum retries');
                }
                await this.sleep(this.retrySleepTimeMs * 2 ** i);
            }
        }
        throw new Error('Failed to fetch blockhash after maximum retries');
    }
    sleep(ms) {
        return new Promise((resolve) => setTimeout(resolve, ms));
    }
    async updateBlockhashCache() {
        const result = await this.fetchBlockhashWithRetry();
        this.recentBlockhashCache = {
            value: result,
            lastUpdated: Date.now(),
        };
    }
    async getLatestBlockhash() {
        if (this.isCacheStale()) {
            await this.refreshBlockhash();
        }
        return this.recentBlockhashCache.value;
    }
    isCacheStale() {
        const lastUpdateTime = this.recentBlockhashCache.lastUpdated;
        return (!lastUpdateTime || Date.now() > lastUpdateTime + this.staleCacheTimeMs);
    }
    /**
     * Refresh the blockhash cache, await a pending refresh if it exists
     */
    async refreshBlockhash() {
        if (!this.blockhashFetchingPromise) {
            this.blockhashFetchingPromise = this.updateBlockhashCache();
            try {
                await this.blockhashFetchingPromise;
            }
            finally {
                this.blockhashFetchingPromise = null;
            }
        }
        else {
            await this.blockhashFetchingPromise;
        }
    }
}
exports.CachedBlockhashFetcher = CachedBlockhashFetcher;
