"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertToNumber = void 0;
const numericConstants_1 = require("../constants/numericConstants");
const convertToNumber = (bigNumber, precision = numericConstants_1.PRICE_PRECISION) => {
    if (!bigNumber)
        return 0;
    return (bigNumber.div(precision).toNumber() +
        bigNumber.mod(precision).toNumber() / precision.toNumber());
};
exports.convertToNumber = convertToNumber;
