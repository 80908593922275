"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.estimateTps = void 0;
async function estimateTps(programId, connection, failed) {
    let signatures = await connection.getSignaturesForAddress(programId, undefined, 'finalized');
    if (failed) {
        signatures = signatures.filter((signature) => signature.err);
    }
    const numberOfSignatures = signatures.length;
    if (numberOfSignatures === 0) {
        return 0;
    }
    return (numberOfSignatures /
        (signatures[0].blockTime - signatures[numberOfSignatures - 1].blockTime));
}
exports.estimateTps = estimateTps;
