"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotSubscribedError = void 0;
class NotSubscribedError extends Error {
    constructor() {
        super(...arguments);
        this.name = 'NotSubscribedError';
    }
}
exports.NotSubscribedError = NotSubscribedError;
