"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isVersionedTransaction = void 0;
const web3_js_1 = require("@solana/web3.js");
const isVersionedTransaction = (tx) => {
    const version = tx === null || tx === void 0 ? void 0 : tx.version;
    const isVersionedTx = tx instanceof web3_js_1.VersionedTransaction || version !== undefined;
    return isVersionedTx;
};
exports.isVersionedTransaction = isVersionedTransaction;
