"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WebsocketSubscription = void 0;
const memcmp_1 = require("../memcmp");
const webSocketProgramAccountSubscriber_1 = require("../accounts/webSocketProgramAccountSubscriber");
class WebsocketSubscription {
    constructor({ orderSubscriber, commitment, skipInitialLoad = false, resubOpts, resyncIntervalMs, decoded = true, }) {
        this.orderSubscriber = orderSubscriber;
        this.commitment = commitment;
        this.skipInitialLoad = skipInitialLoad;
        this.resubOpts = resubOpts;
        this.resyncIntervalMs = resyncIntervalMs;
        this.decoded = decoded;
    }
    async subscribe() {
        if (this.subscriber) {
            return;
        }
        this.subscriber = new webSocketProgramAccountSubscriber_1.WebSocketProgramAccountSubscriber('OrderSubscriber', 'User', this.orderSubscriber.driftClient.program, this.orderSubscriber.decodeFn, {
            filters: [(0, memcmp_1.getUserFilter)(), (0, memcmp_1.getNonIdleUserFilter)()],
            commitment: this.commitment,
        }, this.resubOpts);
        await this.subscriber.subscribe((accountId, account, context, buffer) => {
            var _a;
            const userKey = accountId.toBase58();
            if ((_a = this.decoded) !== null && _a !== void 0 ? _a : true) {
                this.orderSubscriber.tryUpdateUserAccount(userKey, 'decoded', account, context.slot);
            }
            else {
                this.orderSubscriber.tryUpdateUserAccount(userKey, 'buffer', buffer, context.slot);
            }
        });
        if (!this.skipInitialLoad) {
            await this.orderSubscriber.fetch();
        }
        if (this.resyncIntervalMs) {
            const recursiveResync = () => {
                this.resyncTimeoutId = setTimeout(() => {
                    this.orderSubscriber
                        .fetch()
                        .catch((e) => {
                        console.error('Failed to resync in OrderSubscriber');
                        console.log(e);
                    })
                        .finally(() => {
                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                        if (!this.resyncTimeoutId)
                            return;
                        recursiveResync();
                    });
                }, this.resyncIntervalMs);
            };
            recursiveResync();
        }
    }
    async unsubscribe() {
        if (!this.subscriber)
            return;
        await this.subscriber.unsubscribe();
        this.subscriber = undefined;
        if (this.resyncTimeoutId !== undefined) {
            clearTimeout(this.resyncTimeoutId);
            this.resyncTimeoutId = undefined;
        }
    }
}
exports.WebsocketSubscription = WebsocketSubscription;
