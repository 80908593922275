"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrderSubscriber = void 0;
const memcmp_1 = require("../memcmp");
const web3_js_1 = require("@solana/web3.js");
const buffer_1 = require("buffer");
const DLOB_1 = require("../dlob/DLOB");
const PollingSubscription_1 = require("./PollingSubscription");
const WebsocketSubscription_1 = require("./WebsocketSubscription");
const events_1 = require("events");
const index_1 = require("../index");
const user_1 = require("../decode/user");
class OrderSubscriber {
    constructor(config) {
        var _a, _b, _c;
        this.usersAccounts = new Map();
        this.driftClient = config.driftClient;
        this.commitment = config.subscriptionConfig.commitment || 'processed';
        if (config.subscriptionConfig.type === 'polling') {
            this.subscription = new PollingSubscription_1.PollingSubscription({
                orderSubscriber: this,
                frequency: config.subscriptionConfig.frequency,
            });
        }
        else {
            this.subscription = new WebsocketSubscription_1.WebsocketSubscription({
                orderSubscriber: this,
                commitment: this.commitment,
                skipInitialLoad: config.subscriptionConfig.skipInitialLoad,
                resubOpts: {
                    resubTimeoutMs: (_a = config.subscriptionConfig) === null || _a === void 0 ? void 0 : _a.resubTimeoutMs,
                    logResubMessages: (_b = config.subscriptionConfig) === null || _b === void 0 ? void 0 : _b.logResubMessages,
                },
                resyncIntervalMs: config.subscriptionConfig.resyncIntervalMs,
                decoded: config.decodeData,
            });
        }
        if ((_c = config.fastDecode) !== null && _c !== void 0 ? _c : true) {
            this.decodeFn = (name, data) => (0, user_1.decodeUser)(data);
        }
        else {
            this.decodeFn =
                this.driftClient.program.account.user.coder.accounts.decodeUnchecked.bind(this.driftClient.program.account.user.coder.accounts);
        }
        this.eventEmitter = new events_1.EventEmitter();
    }
    async subscribe() {
        await this.subscription.subscribe();
    }
    async fetch() {
        if (this.fetchPromise) {
            return this.fetchPromise;
        }
        this.fetchPromise = new Promise((resolver) => {
            this.fetchPromiseResolver = resolver;
        });
        try {
            const rpcRequestArgs = [
                this.driftClient.program.programId.toBase58(),
                {
                    commitment: this.commitment,
                    filters: [(0, memcmp_1.getUserFilter)(), (0, memcmp_1.getUserWithOrderFilter)()],
                    encoding: 'base64',
                    withContext: true,
                },
            ];
            const rpcJSONResponse = 
            // @ts-ignore
            await this.driftClient.connection._rpcRequest('getProgramAccounts', rpcRequestArgs);
            const rpcResponseAndContext = rpcJSONResponse.result;
            const slot = rpcResponseAndContext.context.slot;
            const programAccountSet = new Set();
            for (const programAccount of rpcResponseAndContext.value) {
                const key = programAccount.pubkey.toString();
                programAccountSet.add(key);
                this.tryUpdateUserAccount(key, 'raw', programAccount.account.data, slot);
                // give event loop a chance to breathe
                await new Promise((resolve) => setTimeout(resolve, 0));
            }
            for (const key of this.usersAccounts.keys()) {
                if (!programAccountSet.has(key)) {
                    this.usersAccounts.delete(key);
                }
                // give event loop a chance to breathe
                await new Promise((resolve) => setTimeout(resolve, 0));
            }
        }
        catch (e) {
            console.error(e);
        }
        finally {
            this.fetchPromiseResolver();
            this.fetchPromise = undefined;
        }
    }
    tryUpdateUserAccount(key, dataType, data, slot) {
        if (!this.mostRecentSlot || slot > this.mostRecentSlot) {
            this.mostRecentSlot = slot;
        }
        this.eventEmitter.emit('updateReceived', new web3_js_1.PublicKey(key), slot, dataType);
        const slotAndUserAccount = this.usersAccounts.get(key);
        if (!slotAndUserAccount || slotAndUserAccount.slot <= slot) {
            let userAccount;
            // Polling leads to a lot of redundant decoding, so we only decode if data is from a fresh slot
            if (dataType === 'raw') {
                // @ts-ignore
                const buffer = buffer_1.Buffer.from(data[0], data[1]);
                const newLastActiveSlot = new index_1.BN(buffer.subarray(4328, 4328 + 8), undefined, 'le');
                if (slotAndUserAccount &&
                    slotAndUserAccount.userAccount.lastActiveSlot.gt(newLastActiveSlot)) {
                    return;
                }
                userAccount = this.decodeFn('User', buffer);
            }
            else if (dataType === 'buffer') {
                const buffer = data;
                const newLastActiveSlot = new index_1.BN(buffer.subarray(4328, 4328 + 8), undefined, 'le');
                if (slotAndUserAccount &&
                    slotAndUserAccount.userAccount.lastActiveSlot.gt(newLastActiveSlot)) {
                    return;
                }
                userAccount = this.decodeFn('User', data);
            }
            else {
                userAccount = data;
            }
            this.eventEmitter.emit('userUpdated', userAccount, new web3_js_1.PublicKey(key), slot, dataType);
            const newOrders = userAccount.orders.filter((order) => {
                var _a;
                return order.slot.toNumber() > ((_a = slotAndUserAccount === null || slotAndUserAccount === void 0 ? void 0 : slotAndUserAccount.slot) !== null && _a !== void 0 ? _a : 0) &&
                    order.slot.toNumber() <= slot;
            });
            if (newOrders.length > 0) {
                this.eventEmitter.emit('orderCreated', userAccount, newOrders, new web3_js_1.PublicKey(key), slot, dataType);
            }
            if (userAccount.hasOpenOrder) {
                this.usersAccounts.set(key, { slot, userAccount });
            }
            else {
                this.usersAccounts.delete(key);
            }
        }
    }
    /**
     * Creates a new DLOB for the order subscriber to fill. This will allow a
     * caller to extend the DLOB Subscriber with a custom DLOB type.
     * @returns New, empty DLOB object.
     */
    createDLOB() {
        return new DLOB_1.DLOB();
    }
    async getDLOB(slot) {
        const dlob = this.createDLOB();
        for (const [key, { userAccount }] of this.usersAccounts.entries()) {
            for (const order of userAccount.orders) {
                dlob.insertOrder(order, key, slot);
            }
        }
        return dlob;
    }
    getSlot() {
        var _a;
        return (_a = this.mostRecentSlot) !== null && _a !== void 0 ? _a : 0;
    }
    async unsubscribe() {
        this.usersAccounts.clear();
        await this.subscription.unsubscribe();
    }
}
exports.OrderSubscriber = OrderSubscriber;
