"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OracleClientCache = void 0;
const oracleClient_1 = require("../factory/oracleClient");
class OracleClientCache {
    constructor() {
        this.cache = new Map();
    }
    get(oracleSource, connection, program) {
        const key = Object.keys(oracleSource)[0];
        if (this.cache.has(key)) {
            return this.cache.get(key);
        }
        const client = (0, oracleClient_1.getOracleClient)(oracleSource, connection, program);
        this.cache.set(key, client);
        return client;
    }
}
exports.OracleClientCache = OracleClientCache;
