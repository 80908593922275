"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PrelaunchOracleClient = void 0;
class PrelaunchOracleClient {
    constructor(connection, program) {
        this.connection = connection;
        this.program = program;
    }
    async getOraclePriceData(pricePublicKey) {
        const accountInfo = await this.connection.getAccountInfo(pricePublicKey);
        return this.getOraclePriceDataFromBuffer(accountInfo.data);
    }
    getOraclePriceDataFromBuffer(buffer) {
        const prelaunchOracle = this.program.account.prelaunchOracle.coder.accounts.decodeUnchecked('PrelaunchOracle', buffer);
        return {
            price: prelaunchOracle.price,
            slot: prelaunchOracle.ammLastUpdateSlot,
            confidence: prelaunchOracle.confidence,
            hasSufficientNumberOfDataPoints: true,
            maxPrice: prelaunchOracle.maxPrice,
        };
    }
}
exports.PrelaunchOracleClient = PrelaunchOracleClient;
