"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PollingSubscription = void 0;
class PollingSubscription {
    constructor({ userMap, frequency, skipInitialLoad = false, }) {
        this.userMap = userMap;
        this.frequency = frequency;
        this.skipInitialLoad = skipInitialLoad;
    }
    async subscribe() {
        if (this.intervalId || this.frequency <= 0) {
            return;
        }
        const executeSync = async () => {
            await this.userMap.sync();
            this.intervalId = setTimeout(executeSync, this.frequency);
        };
        if (!this.skipInitialLoad) {
            await this.userMap.sync();
        }
        executeSync();
    }
    async unsubscribe() {
        if (this.intervalId) {
            clearInterval(this.intervalId);
            this.intervalId = undefined;
        }
    }
}
exports.PollingSubscription = PollingSubscription;
