"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TxSendError = exports.ConfirmationStrategy = void 0;
var ConfirmationStrategy;
(function (ConfirmationStrategy) {
    ConfirmationStrategy["WebSocket"] = "websocket";
    ConfirmationStrategy["Polling"] = "polling";
    ConfirmationStrategy["Combo"] = "combo";
})(ConfirmationStrategy = exports.ConfirmationStrategy || (exports.ConfirmationStrategy = {}));
class TxSendError extends Error {
    constructor(message, code) {
        super(message);
        this.message = message;
        this.code = code;
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, TxSendError);
        }
    }
}
exports.TxSendError = TxSendError;
