"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchUserStatsAccount = exports.fetchUserAccountsUsingKeys = exports.fetchUserAccounts = void 0;
const pda_1 = require("../addresses/pda");
async function fetchUserAccounts(connection, program, authority, limit = 8) {
    const userAccountPublicKeys = new Array();
    for (let i = 0; i < limit; i++) {
        userAccountPublicKeys.push(await (0, pda_1.getUserAccountPublicKey)(program.programId, authority, i));
    }
    return fetchUserAccountsUsingKeys(connection, program, userAccountPublicKeys);
}
exports.fetchUserAccounts = fetchUserAccounts;
async function fetchUserAccountsUsingKeys(connection, program, userAccountPublicKeys) {
    const accountInfos = await connection.getMultipleAccountsInfo(userAccountPublicKeys, 'confirmed');
    return accountInfos.map((accountInfo) => {
        if (!accountInfo) {
            return undefined;
        }
        return program.account.user.coder.accounts.decodeUnchecked('User', accountInfo.data);
    });
}
exports.fetchUserAccountsUsingKeys = fetchUserAccountsUsingKeys;
async function fetchUserStatsAccount(connection, program, authority) {
    const userStatsPublicKey = (0, pda_1.getUserStatsAccountPublicKey)(program.programId, authority);
    const accountInfo = await connection.getAccountInfo(userStatsPublicKey, 'confirmed');
    return accountInfo
        ? program.account.user.coder.accounts.decodeUnchecked('UserStats', accountInfo.data)
        : undefined;
}
exports.fetchUserStatsAccount = fetchUserStatsAccount;
