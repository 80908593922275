"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PhoenixFulfillmentConfigMap = void 0;
class PhoenixFulfillmentConfigMap {
    constructor(driftClient) {
        this.map = new Map();
        this.driftClient = driftClient;
    }
    async add(marketIndex, phoenixMarketAddress) {
        const account = await this.driftClient.getPhoenixV1FulfillmentConfig(phoenixMarketAddress);
        this.map.set(marketIndex, account);
    }
    get(marketIndex) {
        return this.map.get(marketIndex);
    }
}
exports.PhoenixFulfillmentConfigMap = PhoenixFulfillmentConfigMap;
