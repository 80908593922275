"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BaseBlockhashFetcher = void 0;
class BaseBlockhashFetcher {
    constructor(connection, blockhashCommitment) {
        this.connection = connection;
        this.blockhashCommitment = blockhashCommitment;
    }
    async getLatestBlockhash() {
        return this.connection.getLatestBlockhash(this.blockhashCommitment);
    }
}
exports.BaseBlockhashFetcher = BaseBlockhashFetcher;
