"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StrictOraclePrice = void 0;
const anchor_1 = require("@coral-xyz/anchor");
class StrictOraclePrice {
    constructor(current, twap) {
        this.current = current;
        this.twap = twap;
    }
    max() {
        return this.twap ? anchor_1.BN.max(this.twap, this.current) : this.current;
    }
    min() {
        return this.twap ? anchor_1.BN.min(this.twap, this.current) : this.current;
    }
}
exports.StrictOraclePrice = StrictOraclePrice;
