"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getMaxNumberOfSubAccounts = exports.calculateInitUserFee = void 0;
const __1 = require("../");
function calculateInitUserFee(stateAccount) {
    const maxInitFee = new __1.BN(stateAccount.maxInitializeUserFee)
        .mul(__1.LAMPORTS_PRECISION)
        .divn(100);
    const targetUtilization = __1.PERCENTAGE_PRECISION.muln(8).divn(10);
    const accountSpaceUtilization = stateAccount.numberOfSubAccounts
        .addn(1)
        .mul(__1.PERCENTAGE_PRECISION)
        .div(getMaxNumberOfSubAccounts(stateAccount));
    if (accountSpaceUtilization.gt(targetUtilization)) {
        return maxInitFee
            .mul(accountSpaceUtilization.sub(targetUtilization))
            .div(__1.PERCENTAGE_PRECISION.sub(targetUtilization));
    }
    else {
        return __1.ZERO;
    }
}
exports.calculateInitUserFee = calculateInitUserFee;
function getMaxNumberOfSubAccounts(stateAccount) {
    if (stateAccount.maxNumberOfSubAccounts <= 5) {
        return new __1.BN(stateAccount.maxNumberOfSubAccounts);
    }
    return new __1.BN(stateAccount.maxNumberOfSubAccounts).muln(100);
}
exports.getMaxNumberOfSubAccounts = getMaxNumberOfSubAccounts;
