import { useEffect, useState } from 'react';
import { useUpdate } from 'react-use';

const useMounted = () => {
	const [mounted, setMounted] = useState(false);
	const update = useUpdate();
	useEffect(() => {
		if (mounted == false) {
			setMounted(true);
			update();
		}
	}, [update]);
	return mounted;
};

export default useMounted;
