"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseTokenAccount = void 0;
const spl_token_1 = require("@solana/spl-token");
function parseTokenAccount(data, pubkey) {
    // mock AccountInfo so unpackAccount can be used
    const accountInfo = {
        data,
        owner: spl_token_1.TOKEN_PROGRAM_ID,
        executable: false,
        lamports: 0,
    };
    return (0, spl_token_1.unpackAccount)(pubkey, accountInfo, spl_token_1.TOKEN_PROGRAM_ID);
}
exports.parseTokenAccount = parseTokenAccount;
