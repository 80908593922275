"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClockSubscriber = void 0;
const web3_js_1 = require("@solana/web3.js");
const events_1 = require("events");
const __1 = require("..");
class ClockSubscriber {
    get latestSlot() {
        return this._latestSlot;
    }
    get currentTs() {
        return this._currentTs;
    }
    constructor(connection, config) {
        this.connection = connection;
        this.isUnsubscribing = false;
        this.receivingData = false;
        this.eventEmitter = new events_1.EventEmitter();
        this.resubTimeoutMs = config === null || config === void 0 ? void 0 : config.resubTimeoutMs;
        this.commitment = (config === null || config === void 0 ? void 0 : config.commitment) || 'confirmed';
        if (this.resubTimeoutMs < 1000) {
            console.log('resubTimeoutMs should be at least 1000ms to avoid spamming resub');
        }
    }
    async subscribe() {
        if (this.subscriptionId != null) {
            return;
        }
        this.subscriptionId = this.connection.onAccountChange(web3_js_1.SYSVAR_CLOCK_PUBKEY, (acctInfo, context) => {
            if (!this.latestSlot || this.latestSlot < context.slot) {
                if (this.resubTimeoutMs && !this.isUnsubscribing) {
                    this.receivingData = true;
                    clearTimeout(this.timeoutId);
                    this.setTimeout();
                }
                this._latestSlot = context.slot;
                this._currentTs = new __1.BN(acctInfo.data.subarray(32, 39), undefined, 'le').toNumber();
                this.eventEmitter.emit('clockUpdate', this.currentTs);
            }
        }, this.commitment);
        if (this.resubTimeoutMs) {
            this.receivingData = true;
            this.setTimeout();
        }
    }
    setTimeout() {
        this.timeoutId = setTimeout(async () => {
            if (this.isUnsubscribing) {
                // If we are in the process of unsubscribing, do not attempt to resubscribe
                return;
            }
            if (this.receivingData) {
                console.log(`No new slot in ${this.resubTimeoutMs}ms, slot subscriber resubscribing`);
                await this.unsubscribe(true);
                this.receivingData = false;
                await this.subscribe();
            }
        }, this.resubTimeoutMs);
    }
    getUnixTs() {
        return this.currentTs;
    }
    async unsubscribe(onResub = false) {
        if (!onResub) {
            this.resubTimeoutMs = undefined;
        }
        this.isUnsubscribing = true;
        clearTimeout(this.timeoutId);
        this.timeoutId = undefined;
        if (this.subscriptionId != null) {
            await this.connection.removeAccountChangeListener(this.subscriptionId);
            this.subscriptionId = undefined;
            this.isUnsubscribing = false;
        }
        else {
            this.isUnsubscribing = false;
        }
    }
}
exports.ClockSubscriber = ClockSubscriber;
