"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PollingOracleAccountSubscriber = void 0;
const types_1 = require("./types");
const events_1 = require("events");
class PollingOracleAccountSubscriber {
    constructor(publicKey, oracleClient, accountLoader) {
        this.isSubscribed = false;
        this.publicKey = publicKey;
        this.oracleClient = oracleClient;
        this.accountLoader = accountLoader;
        this.eventEmitter = new events_1.EventEmitter();
    }
    async subscribe() {
        if (this.isSubscribed) {
            return true;
        }
        await this.addToAccountLoader();
        let subscriptionSucceeded = false;
        let retries = 0;
        while (!subscriptionSucceeded && retries < 5) {
            await this.fetch();
            subscriptionSucceeded = this.didSubscriptionSucceed();
            retries++;
        }
        if (subscriptionSucceeded) {
            this.eventEmitter.emit('update');
        }
        this.isSubscribed = subscriptionSucceeded;
        return subscriptionSucceeded;
    }
    async addToAccountLoader() {
        if (this.callbackId) {
            return;
        }
        this.callbackId = await this.accountLoader.addAccount(this.publicKey, async (buffer, slot) => {
            const oraclePriceData = await this.oracleClient.getOraclePriceDataFromBuffer(buffer);
            this.oraclePriceData = { data: oraclePriceData, slot };
            // @ts-ignore
            this.eventEmitter.emit('oracleUpdate', oraclePriceData);
            this.eventEmitter.emit('update');
        });
        this.errorCallbackId = this.accountLoader.addErrorCallbacks((error) => {
            this.eventEmitter.emit('error', error);
        });
    }
    async fetch() {
        await this.accountLoader.load();
        const { buffer, slot } = this.accountLoader.getBufferAndSlot(this.publicKey);
        this.oraclePriceData = {
            data: await this.oracleClient.getOraclePriceDataFromBuffer(buffer),
            slot,
        };
    }
    async unsubscribe() {
        if (!this.isSubscribed) {
            return;
        }
        this.accountLoader.removeAccount(this.publicKey, this.callbackId);
        this.callbackId = undefined;
        this.accountLoader.removeErrorCallbacks(this.errorCallbackId);
        this.errorCallbackId = undefined;
        this.isSubscribed = false;
    }
    assertIsSubscribed() {
        if (!this.isSubscribed) {
            throw new types_1.NotSubscribedError('You must call `subscribe` before using this function');
        }
    }
    getOraclePriceData() {
        this.assertIsSubscribed();
        return this.oraclePriceData;
    }
    didSubscriptionSucceed() {
        return !!this.oraclePriceData;
    }
}
exports.PollingOracleAccountSubscriber = PollingOracleAccountSubscriber;
