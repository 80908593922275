"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserStatsMap = void 0;
const __1 = require("..");
const web3_js_1 = require("@solana/web3.js");
class UserStatsMap {
    /**
     * Creates a new UserStatsMap instance.
     *
     * @param {DriftClient} driftClient - The DriftClient instance.
     * @param {BulkAccountLoader} [bulkAccountLoader] - If not provided, a new BulkAccountLoader with polling disabled will be created.
     */
    constructor(driftClient, bulkAccountLoader) {
        /**
         * map from authority pubkey to UserStats
         */
        this.userStatsMap = new Map();
        this.driftClient = driftClient;
        if (!bulkAccountLoader) {
            bulkAccountLoader = new __1.BulkAccountLoader(driftClient.connection, driftClient.opts.commitment, 0);
        }
        this.bulkAccountLoader = bulkAccountLoader;
    }
    async subscribe(authorities) {
        if (this.size() > 0) {
            return;
        }
        await this.driftClient.subscribe();
        await this.sync(authorities);
    }
    /**
     *
     * @param authority that owns the UserStatsAccount
     * @param userStatsAccount optional UserStatsAccount to subscribe to, if undefined will be fetched later
     * @param skipFetch if true, will not immediately fetch the UserStatsAccount
     */
    async addUserStat(authority, userStatsAccount, skipFetch) {
        const userStat = new __1.UserStats({
            driftClient: this.driftClient,
            userStatsAccountPublicKey: (0, __1.getUserStatsAccountPublicKey)(this.driftClient.program.programId, authority),
            accountSubscription: {
                type: 'polling',
                accountLoader: this.bulkAccountLoader,
            },
        });
        if (skipFetch) {
            await userStat.accountSubscriber.addToAccountLoader();
        }
        else {
            await userStat.subscribe(userStatsAccount);
        }
        this.userStatsMap.set(authority.toString(), userStat);
    }
    async updateWithOrderRecord(record, userMap) {
        const user = await userMap.mustGet(record.user.toString());
        if (!this.has(user.getUserAccount().authority.toString())) {
            await this.addUserStat(user.getUserAccount().authority, undefined, false);
        }
    }
    async updateWithEventRecord(record, userMap) {
        if (record.eventType === 'DepositRecord') {
            const depositRecord = record;
            await this.mustGet(depositRecord.userAuthority.toString());
        }
        else if (record.eventType === 'FundingPaymentRecord') {
            const fundingPaymentRecord = record;
            await this.mustGet(fundingPaymentRecord.userAuthority.toString());
        }
        else if (record.eventType === 'LiquidationRecord') {
            if (!userMap) {
                return;
            }
            const liqRecord = record;
            const user = await userMap.mustGet(liqRecord.user.toString());
            await this.mustGet(user.getUserAccount().authority.toString());
            const liquidatorUser = await userMap.mustGet(liqRecord.liquidator.toString());
            await this.mustGet(liquidatorUser.getUserAccount().authority.toString());
        }
        else if (record.eventType === 'OrderRecord') {
            if (!userMap) {
                return;
            }
            const orderRecord = record;
            await userMap.updateWithOrderRecord(orderRecord);
        }
        else if (record.eventType === 'OrderActionRecord') {
            if (!userMap) {
                return;
            }
            const actionRecord = record;
            if (actionRecord.taker) {
                const taker = await userMap.mustGet(actionRecord.taker.toString());
                await this.mustGet(taker.getUserAccount().authority.toString());
            }
            if (actionRecord.maker) {
                const maker = await userMap.mustGet(actionRecord.maker.toString());
                await this.mustGet(maker.getUserAccount().authority.toString());
            }
        }
        else if (record.eventType === 'SettlePnlRecord') {
            if (!userMap) {
                return;
            }
            const settlePnlRecord = record;
            const user = await userMap.mustGet(settlePnlRecord.user.toString());
            await this.mustGet(user.getUserAccount().authority.toString());
        }
        else if (record.eventType === 'NewUserRecord') {
            const newUserRecord = record;
            await this.mustGet(newUserRecord.userAuthority.toString());
        }
        else if (record.eventType === 'LPRecord') {
            if (!userMap) {
                return;
            }
            const lpRecord = record;
            const user = await userMap.mustGet(lpRecord.user.toString());
            await this.mustGet(user.getUserAccount().authority.toString());
        }
        else if (record.eventType === 'InsuranceFundStakeRecord') {
            const ifStakeRecord = record;
            await this.mustGet(ifStakeRecord.userAuthority.toString());
        }
    }
    has(authorityPublicKey) {
        return this.userStatsMap.has(authorityPublicKey);
    }
    get(authorityPublicKey) {
        return this.userStatsMap.get(authorityPublicKey);
    }
    /**
     * Enforce that a UserStats will exist for the given authorityPublicKey,
     * reading one from the blockchain if necessary.
     * @param authorityPublicKey
     * @returns
     */
    async mustGet(authorityPublicKey) {
        if (!this.has(authorityPublicKey)) {
            await this.addUserStat(new web3_js_1.PublicKey(authorityPublicKey), undefined, false);
        }
        return this.get(authorityPublicKey);
    }
    values() {
        return this.userStatsMap.values();
    }
    size() {
        return this.userStatsMap.size;
    }
    /**
     * Sync the UserStatsMap
     * @param authorities list of authorities to derive UserStatsAccount public keys from.
     * You may want to get this list from UserMap in order to filter out idle users
     */
    async sync(authorities) {
        await Promise.all(authorities.map((authority) => this.addUserStat(authority, undefined, true)));
        await this.bulkAccountLoader.load();
    }
    async unsubscribe() {
        for (const [key, userStats] of this.userStatsMap.entries()) {
            await userStats.unsubscribe();
            this.userStatsMap.delete(key);
        }
    }
}
exports.UserStatsMap = UserStatsMap;
