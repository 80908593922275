"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EventList = void 0;
class Node {
    constructor(event, next, prev) {
        this.event = event;
        this.next = next;
        this.prev = prev;
    }
}
class EventList {
    constructor(eventType, maxSize, sortFn, orderDirection) {
        this.eventType = eventType;
        this.maxSize = maxSize;
        this.sortFn = sortFn;
        this.orderDirection = orderDirection;
        this.size = 0;
    }
    insert(event) {
        this.size++;
        const newNode = new Node(event);
        if (this.head === undefined) {
            this.head = this.tail = newNode;
            return;
        }
        if (this.sortFn(this.head.event, newNode.event) ===
            (this.orderDirection === 'asc' ? 'less than' : 'greater than')) {
            this.head.prev = newNode;
            newNode.next = this.head;
            this.head = newNode;
        }
        else {
            let currentNode = this.head;
            while (currentNode.next !== undefined &&
                this.sortFn(currentNode.next.event, newNode.event) !==
                    (this.orderDirection === 'asc' ? 'less than' : 'greater than')) {
                currentNode = currentNode.next;
            }
            newNode.next = currentNode.next;
            if (currentNode.next !== undefined) {
                newNode.next.prev = newNode;
            }
            else {
                this.tail = newNode;
            }
            currentNode.next = newNode;
            newNode.prev = currentNode;
        }
        if (this.size > this.maxSize) {
            this.detach();
        }
    }
    detach() {
        const node = this.tail;
        if (node.prev !== undefined) {
            node.prev.next = node.next;
        }
        else {
            this.head = node.next;
        }
        if (node.next !== undefined) {
            node.next.prev = node.prev;
        }
        else {
            this.tail = node.prev;
        }
        this.size--;
    }
    toArray() {
        return Array.from(this);
    }
    *[Symbol.iterator]() {
        let node = this.head;
        while (node) {
            yield node.event;
            node = node.next;
        }
    }
}
exports.EventList = EventList;
