"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.perpTierIsAsSafeAs = exports.getSpotMarketTierNumber = exports.getPerpMarketTierNumber = void 0;
const types_1 = require("../types");
function getPerpMarketTierNumber(perpMarket) {
    if ((0, types_1.isVariant)(perpMarket.contractTier, 'a')) {
        return 0;
    }
    else if ((0, types_1.isVariant)(perpMarket.contractTier, 'b')) {
        return 1;
    }
    else if ((0, types_1.isVariant)(perpMarket.contractTier, 'c')) {
        return 2;
    }
    else if ((0, types_1.isVariant)(perpMarket.contractTier, 'speculative')) {
        return 3;
    }
    else if ((0, types_1.isVariant)(perpMarket.contractTier, 'highlySpeculative')) {
        return 4;
    }
    else {
        return 5;
    }
}
exports.getPerpMarketTierNumber = getPerpMarketTierNumber;
function getSpotMarketTierNumber(spotMarket) {
    if ((0, types_1.isVariant)(spotMarket.assetTier, 'collateral')) {
        return 0;
    }
    else if ((0, types_1.isVariant)(spotMarket.assetTier, 'protected')) {
        return 1;
    }
    else if ((0, types_1.isVariant)(spotMarket.assetTier, 'cross')) {
        return 2;
    }
    else if ((0, types_1.isVariant)(spotMarket.assetTier, 'isolated')) {
        return 3;
    }
    else if ((0, types_1.isVariant)(spotMarket.assetTier, 'unlisted')) {
        return 4;
    }
    else {
        return 5;
    }
}
exports.getSpotMarketTierNumber = getSpotMarketTierNumber;
function perpTierIsAsSafeAs(perpTier, otherPerpTier, otherSpotTier) {
    const asSafeAsPerp = perpTier <= otherPerpTier;
    const asSafeAsSpot = otherSpotTier === 4 || (otherSpotTier >= 2 && perpTier <= 2);
    return asSafeAsSpot && asSafeAsPerp;
}
exports.perpTierIsAsSafeAs = perpTierIsAsSafeAs;
