"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PollingLogProvider = void 0;
const fetchLogs_1 = require("./fetchLogs");
class PollingLogProvider {
    constructor(connection, address, commitment, frequency = 15 * 1000, batchSize) {
        this.connection = connection;
        this.address = address;
        this.frequency = frequency;
        this.batchSize = batchSize;
        this.firstFetch = true;
        this.finality = commitment === 'finalized' ? 'finalized' : 'confirmed';
    }
    async subscribe(callback, skipHistory) {
        if (this.intervalId) {
            return true;
        }
        this.intervalId = setInterval(async () => {
            if (this.mutex === 1) {
                return;
            }
            this.mutex = 1;
            try {
                const response = await (0, fetchLogs_1.fetchLogs)(this.connection, this.address, this.finality, undefined, this.mostRecentSeenTx, 
                // If skipping history, only fetch one log back, not the maximum amount available
                skipHistory && this.firstFetch ? 1 : undefined, this.batchSize);
                if (response === undefined) {
                    return;
                }
                this.firstFetch = false;
                const { mostRecentTx, transactionLogs } = response;
                for (const { txSig, slot, logs } of transactionLogs) {
                    callback(txSig, slot, logs, response.mostRecentBlockTime, undefined);
                }
                this.mostRecentSeenTx = mostRecentTx;
            }
            catch (e) {
                console.error('PollingLogProvider threw an Error');
                console.error(e);
            }
            finally {
                this.mutex = 0;
            }
        }, this.frequency);
        return true;
    }
    isSubscribed() {
        return this.intervalId !== undefined;
    }
    async unsubscribe() {
        if (this.intervalId !== undefined) {
            clearInterval(this.intervalId);
            this.intervalId = undefined;
        }
        return true;
    }
}
exports.PollingLogProvider = PollingLogProvider;
