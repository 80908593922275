"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SlotSubscriber = void 0;
const events_1 = require("events");
class SlotSubscriber {
    constructor(connection, config) {
        this.connection = connection;
        this.isUnsubscribing = false;
        this.receivingData = false;
        this.eventEmitter = new events_1.EventEmitter();
        this.resubTimeoutMs = config === null || config === void 0 ? void 0 : config.resubTimeoutMs;
        if (this.resubTimeoutMs < 1000) {
            console.log('resubTimeoutMs should be at least 1000ms to avoid spamming resub');
        }
    }
    async subscribe() {
        if (this.subscriptionId != null) {
            return;
        }
        this.currentSlot = await this.connection.getSlot('confirmed');
        this.subscriptionId = this.connection.onSlotChange((slotInfo) => {
            if (!this.currentSlot || this.currentSlot < slotInfo.slot) {
                if (this.resubTimeoutMs && !this.isUnsubscribing) {
                    this.receivingData = true;
                    clearTimeout(this.timeoutId);
                    this.setTimeout();
                }
                this.currentSlot = slotInfo.slot;
                this.eventEmitter.emit('newSlot', slotInfo.slot);
            }
        });
        if (this.resubTimeoutMs) {
            this.receivingData = true;
            this.setTimeout();
        }
    }
    setTimeout() {
        this.timeoutId = setTimeout(async () => {
            if (this.isUnsubscribing) {
                // If we are in the process of unsubscribing, do not attempt to resubscribe
                return;
            }
            if (this.receivingData) {
                console.log(`No new slot in ${this.resubTimeoutMs}ms, slot subscriber resubscribing`);
                await this.unsubscribe(true);
                this.receivingData = false;
                await this.subscribe();
            }
        }, this.resubTimeoutMs);
    }
    getSlot() {
        return this.currentSlot;
    }
    async unsubscribe(onResub = false) {
        if (!onResub) {
            this.resubTimeoutMs = undefined;
        }
        this.isUnsubscribing = true;
        clearTimeout(this.timeoutId);
        this.timeoutId = undefined;
        if (this.subscriptionId != null) {
            await this.connection.removeSlotChangeListener(this.subscriptionId);
            this.subscriptionId = undefined;
            this.isUnsubscribing = false;
        }
        else {
            this.isUnsubscribing = false;
        }
    }
}
exports.SlotSubscriber = SlotSubscriber;
