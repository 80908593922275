"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PriorityFeeMethod = exports.DEFAULT_PRIORITY_FEE_MAP_FREQUENCY_MS = void 0;
exports.DEFAULT_PRIORITY_FEE_MAP_FREQUENCY_MS = 10000;
var PriorityFeeMethod;
(function (PriorityFeeMethod) {
    PriorityFeeMethod["SOLANA"] = "solana";
    PriorityFeeMethod["HELIUS"] = "helius";
    PriorityFeeMethod["DRIFT"] = "drift";
})(PriorityFeeMethod = exports.PriorityFeeMethod || (exports.PriorityFeeMethod = {}));
