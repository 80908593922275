"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WebsocketSubscription = void 0;
const memcmp_1 = require("../memcmp");
const webSocketProgramAccountSubscriber_1 = require("../accounts/webSocketProgramAccountSubscriber");
class WebsocketSubscription {
    constructor({ userMap, commitment, skipInitialLoad = false, resubOpts, includeIdle = false, decodeFn, }) {
        this.userMap = userMap;
        this.commitment = commitment;
        this.skipInitialLoad = skipInitialLoad;
        this.resubOpts = resubOpts;
        this.includeIdle = includeIdle || false;
        this.decodeFn = decodeFn;
    }
    async subscribe() {
        if (!this.subscriber) {
            const filters = [(0, memcmp_1.getUserFilter)()];
            if (!this.includeIdle) {
                filters.push((0, memcmp_1.getNonIdleUserFilter)());
            }
            this.subscriber = new webSocketProgramAccountSubscriber_1.WebSocketProgramAccountSubscriber('UserMap', 'User', this.userMap.driftClient.program, this.decodeFn, {
                filters,
                commitment: this.commitment,
            }, this.resubOpts);
        }
        await this.subscriber.subscribe((accountId, account, context) => {
            const userKey = accountId.toBase58();
            this.userMap.updateUserAccount(userKey, account, context.slot);
        });
        if (!this.skipInitialLoad) {
            await this.userMap.sync();
        }
    }
    async unsubscribe() {
        if (!this.subscriber)
            return;
        await this.subscriber.unsubscribe();
        this.subscriber = undefined;
    }
}
exports.WebsocketSubscription = WebsocketSubscription;
