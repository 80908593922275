"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FastSingleTxSender = void 0;
const types_1 = require("./types");
const anchor_1 = require("@coral-xyz/anchor");
const baseTxSender_1 = require("./baseTxSender");
const DEFAULT_TIMEOUT = 35000;
const DEFAULT_BLOCKHASH_REFRESH = 10000;
class FastSingleTxSender extends baseTxSender_1.BaseTxSender {
    constructor({ connection, wallet, opts = { ...anchor_1.AnchorProvider.defaultOptions(), maxRetries: 0 }, timeout = DEFAULT_TIMEOUT, blockhashRefreshInterval = DEFAULT_BLOCKHASH_REFRESH, additionalConnections = new Array(), skipConfirmation = false, confirmInBackground = false, blockhashCommitment = 'finalized', confirmationStrategy = types_1.ConfirmationStrategy.Combo, trackTxLandRate, txHandler, txLandRateLookbackWindowMinutes, landRateToFeeFunc, }) {
        super({
            connection,
            wallet,
            opts,
            timeout,
            additionalConnections,
            confirmationStrategy,
            txHandler,
            trackTxLandRate,
            txLandRateLookbackWindowMinutes,
            landRateToFeeFunc,
        });
        this.timoutCount = 0;
        this.connection = connection;
        this.wallet = wallet;
        this.opts = opts;
        this.timeout = timeout;
        this.blockhashRefreshInterval = blockhashRefreshInterval;
        this.additionalConnections = additionalConnections;
        this.skipConfirmation = skipConfirmation;
        this.confirmInBackground = confirmInBackground;
        this.blockhashCommitment = blockhashCommitment;
        this.startBlockhashRefreshLoop();
    }
    startBlockhashRefreshLoop() {
        if (this.blockhashRefreshInterval > 0) {
            this.blockhashIntervalId = setInterval(async () => {
                try {
                    this.recentBlockhash = await this.connection.getLatestBlockhash(this.blockhashCommitment);
                }
                catch (e) {
                    console.error('Error in startBlockhashRefreshLoop: ', e);
                }
            }, this.blockhashRefreshInterval);
        }
    }
    async sendRawTransaction(rawTransaction, opts) {
        var _a, _b;
        let txid;
        try {
            txid = await this.connection.sendRawTransaction(rawTransaction, opts);
            (_a = this.txSigCache) === null || _a === void 0 ? void 0 : _a.set(txid, false);
            this.sendToAdditionalConnections(rawTransaction, opts);
        }
        catch (e) {
            console.error(e);
            throw e;
        }
        let slot;
        if (!this.skipConfirmation) {
            try {
                if (this.confirmInBackground) {
                    this.confirmTransaction(txid, opts.commitment).then(async (result) => {
                        var _a;
                        (_a = this.txSigCache) === null || _a === void 0 ? void 0 : _a.set(txid, true);
                        await this.checkConfirmationResultForError(txid, result.value);
                        slot = result.context.slot;
                    });
                }
                else {
                    const result = await this.confirmTransaction(txid, opts.commitment);
                    (_b = this.txSigCache) === null || _b === void 0 ? void 0 : _b.set(txid, true);
                    await this.checkConfirmationResultForError(txid, result.value);
                    slot = result.context.slot;
                }
            }
            catch (e) {
                console.error(e);
                throw e;
            }
        }
        return { txSig: txid, slot };
    }
}
exports.FastSingleTxSender = FastSingleTxSender;
