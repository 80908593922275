"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isUserBankrupt = void 0;
const __1 = require("..");
function isUserBankrupt(user) {
    const userAccount = user.getUserAccount();
    let hasLiability = false;
    for (const position of userAccount.spotPositions) {
        if (position.scaledBalance.gt(__1.ZERO)) {
            if ((0, __1.isVariant)(position.balanceType, 'deposit')) {
                return false;
            }
            if ((0, __1.isVariant)(position.balanceType, 'borrow')) {
                hasLiability = true;
            }
        }
    }
    for (const position of userAccount.perpPositions) {
        if (!position.baseAssetAmount.eq(__1.ZERO) ||
            position.quoteAssetAmount.gt(__1.ZERO) ||
            (0, __1.hasOpenOrders)(position) ||
            position.lpShares.gt(__1.ZERO)) {
            return false;
        }
        if (position.quoteAssetAmount.lt(__1.ZERO)) {
            hasLiability = true;
        }
    }
    return hasLiability;
}
exports.isUserBankrupt = isUserBankrupt;
