"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertPythPrice = exports.PythClient = void 0;
const client_1 = require("@pythnetwork/client");
const anchor_1 = require("@coral-xyz/anchor");
const numericConstants_1 = require("../constants/numericConstants");
class PythClient {
    constructor(connection, multiple = numericConstants_1.ONE, stableCoin = false) {
        this.connection = connection;
        this.multiple = multiple;
        this.stableCoin = stableCoin;
    }
    async getOraclePriceData(pricePublicKey) {
        const accountInfo = await this.connection.getAccountInfo(pricePublicKey);
        return this.getOraclePriceDataFromBuffer(accountInfo.data);
    }
    getOraclePriceDataFromBuffer(buffer) {
        const priceData = (0, client_1.parsePriceData)(buffer);
        const confidence = convertPythPrice(priceData.confidence, priceData.exponent, this.multiple);
        const minPublishers = Math.min(priceData.numComponentPrices, 3);
        let price = convertPythPrice(priceData.aggregate.price, priceData.exponent, this.multiple);
        if (this.stableCoin) {
            price = getStableCoinPrice(price, confidence);
        }
        return {
            price,
            slot: new anchor_1.BN(priceData.lastSlot.toString()),
            confidence,
            twap: convertPythPrice(priceData.twap.value, priceData.exponent, this.multiple),
            twapConfidence: convertPythPrice(priceData.twac.value, priceData.exponent, this.multiple),
            hasSufficientNumberOfDataPoints: priceData.numQuoters >= minPublishers,
        };
    }
}
exports.PythClient = PythClient;
function convertPythPrice(price, exponent, multiple) {
    exponent = Math.abs(exponent);
    const pythPrecision = numericConstants_1.TEN.pow(new anchor_1.BN(exponent).abs()).div(multiple);
    return new anchor_1.BN(price * Math.pow(10, exponent))
        .mul(numericConstants_1.PRICE_PRECISION)
        .div(pythPrecision);
}
exports.convertPythPrice = convertPythPrice;
const fiveBPS = new anchor_1.BN(500);
function getStableCoinPrice(price, confidence) {
    if (price.sub(numericConstants_1.QUOTE_PRECISION).abs().lt(anchor_1.BN.min(confidence, fiveBPS))) {
        return numericConstants_1.QUOTE_PRECISION;
    }
    else {
        return price;
    }
}
