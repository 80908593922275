"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calculatePerpFuelBonus = exports.calculateSpotFuelBonus = exports.calculateInsuranceFuelBonus = void 0;
const anchor_1 = require("@coral-xyz/anchor");
const numericConstants_1 = require("../constants/numericConstants");
function calculateInsuranceFuelBonus(spotMarket, tokenStakeAmount, fuelBonusNumerator) {
    const result = tokenStakeAmount
        .abs()
        .mul(fuelBonusNumerator)
        .mul(new anchor_1.BN(spotMarket.fuelBoostInsurance))
        .div(numericConstants_1.FUEL_WINDOW)
        .div(numericConstants_1.QUOTE_PRECISION.div(new anchor_1.BN(10)));
    return result;
}
exports.calculateInsuranceFuelBonus = calculateInsuranceFuelBonus;
function calculateSpotFuelBonus(spotMarket, signedTokenValue, fuelBonusNumerator) {
    let result;
    if (signedTokenValue.abs().lte(numericConstants_1.QUOTE_PRECISION)) {
        result = numericConstants_1.ZERO;
    }
    else if (signedTokenValue.gt(new anchor_1.BN(0))) {
        result = signedTokenValue
            .abs()
            .mul(fuelBonusNumerator)
            .mul(new anchor_1.BN(spotMarket.fuelBoostDeposits))
            .div(numericConstants_1.FUEL_WINDOW)
            .div(numericConstants_1.QUOTE_PRECISION.div(new anchor_1.BN(10)));
    }
    else {
        result = signedTokenValue
            .abs()
            .mul(fuelBonusNumerator)
            .mul(new anchor_1.BN(spotMarket.fuelBoostBorrows))
            .div(numericConstants_1.FUEL_WINDOW)
            .div(numericConstants_1.QUOTE_PRECISION.div(new anchor_1.BN(10)));
    }
    return result;
}
exports.calculateSpotFuelBonus = calculateSpotFuelBonus;
function calculatePerpFuelBonus(perpMarket, baseAssetValue, fuelBonusNumerator) {
    let result;
    if (baseAssetValue.abs().lte(numericConstants_1.QUOTE_PRECISION)) {
        result = new anchor_1.BN(0);
    }
    else {
        result = baseAssetValue
            .abs()
            .mul(fuelBonusNumerator)
            .mul(new anchor_1.BN(perpMarket.fuelBoostPosition))
            .div(numericConstants_1.FUEL_WINDOW)
            .div(numericConstants_1.QUOTE_PRECISION.div(new anchor_1.BN(10)));
    }
    return result;
}
exports.calculatePerpFuelBonus = calculatePerpFuelBonus;
