"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calculateMaxRemainingDeposit = exports.calculateSpotMarketMarginRatio = exports.castNumberToSpotPrecision = void 0;
const anchor_1 = require("@coral-xyz/anchor");
const types_1 = require("../types");
const spotBalance_1 = require("./spotBalance");
const numericConstants_1 = require("../constants/numericConstants");
const utils_1 = require("./utils");
function castNumberToSpotPrecision(value, spotMarket) {
    if (typeof value === 'number') {
        return (0, utils_1.numberToSafeBN)(value, new anchor_1.BN(Math.pow(10, spotMarket.decimals)));
    }
    else {
        return value.mul(new anchor_1.BN(Math.pow(10, spotMarket.decimals)));
    }
}
exports.castNumberToSpotPrecision = castNumberToSpotPrecision;
function calculateSpotMarketMarginRatio(market, oraclePrice, marginCategory, size, balanceType, customMarginRatio = 0) {
    let marginRatio;
    if ((0, types_1.isVariant)(balanceType, 'deposit')) {
        const assetWeight = (0, spotBalance_1.calculateAssetWeight)(size, oraclePrice, market, marginCategory);
        marginRatio = numericConstants_1.MARGIN_PRECISION.sub(assetWeight).toNumber();
    }
    else {
        const liabilityWeight = (0, spotBalance_1.calculateLiabilityWeight)(size, market, marginCategory);
        marginRatio = liabilityWeight.sub(numericConstants_1.MARGIN_PRECISION).toNumber();
    }
    if (marginCategory === 'Initial') {
        // use lowest leverage between max allowed and optional user custom max
        return Math.max(marginRatio, customMarginRatio);
    }
    return marginRatio;
}
exports.calculateSpotMarketMarginRatio = calculateSpotMarketMarginRatio;
/**
 * Returns the maximum remaining deposit that can be made to the spot market. If the maxTokenDeposits on the market is zero then there is no limit and this function will also return zero. (so that needs to be checked)
 * @param market
 * @returns
 */
function calculateMaxRemainingDeposit(market) {
    const marketMaxTokenDeposits = market.maxTokenDeposits;
    if (marketMaxTokenDeposits.eq(numericConstants_1.ZERO)) {
        // If the maxTokenDeposits is set to zero then that means there is no limit. Return the largest number we can to represent infinite available deposit.
        return numericConstants_1.ZERO;
    }
    const totalDepositsTokenAmount = (0, spotBalance_1.getTokenAmount)(market.depositBalance, market, types_1.SpotBalanceType.DEPOSIT);
    return marketMaxTokenDeposits.sub(totalDepositsTokenAmount);
}
exports.calculateMaxRemainingDeposit = calculateMaxRemainingDeposit;
