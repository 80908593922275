"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Wallet = void 0;
class Wallet {
    constructor(payer) {
        this.payer = payer;
    }
    async signTransaction(tx) {
        tx.partialSign(this.payer);
        return tx;
    }
    async signVersionedTransaction(tx) {
        tx.sign([this.payer]);
        return tx;
    }
    async signAllTransactions(txs) {
        return txs.map((t) => {
            t.partialSign(this.payer);
            return t;
        });
    }
    async signAllVersionedTransactions(txs) {
        return txs.map((t) => {
            t.sign([this.payer]);
            return t;
        });
    }
    get publicKey() {
        return this.payer.publicKey;
    }
}
exports.Wallet = Wallet;
