"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.promiseTimeout = void 0;
function promiseTimeout(promise, timeoutMs) {
    let timeoutId;
    const timeoutPromise = new Promise((resolve) => {
        timeoutId = setTimeout(() => resolve(null), timeoutMs);
    });
    return Promise.race([promise, timeoutPromise]).then((result) => {
        clearTimeout(timeoutId);
        return result;
    });
}
exports.promiseTimeout = promiseTimeout;
