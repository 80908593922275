"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AverageOverSlotsStrategy = void 0;
class AverageOverSlotsStrategy {
    calculate(samples) {
        if (samples.length === 0) {
            return 0;
        }
        let runningSumFees = 0;
        for (let i = 0; i < samples.length; i++) {
            runningSumFees += samples[i].prioritizationFee;
        }
        return runningSumFees / samples.length;
    }
}
exports.AverageOverSlotsStrategy = AverageOverSlotsStrategy;
