"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchSolanaPriorityFee = void 0;
async function fetchSolanaPriorityFee(connection, lookbackDistance, addresses) {
    try {
        // @ts-ignore
        const rpcJSONResponse = await connection._rpcRequest('getRecentPrioritizationFees', [addresses]);
        const results = rpcJSONResponse === null || rpcJSONResponse === void 0 ? void 0 : rpcJSONResponse.result;
        if (!results.length)
            return;
        // Sort and filter results based on the slot lookback setting
        const descResults = results.sort((a, b) => b.slot - a.slot);
        const cutoffSlot = descResults[0].slot - lookbackDistance;
        return descResults.filter((result) => result.slot >= cutoffSlot);
    }
    catch (err) {
        console.error(err);
    }
    return [];
}
exports.fetchSolanaPriorityFee = fetchSolanaPriorityFee;
