"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertPythPrice = exports.PythPullClient = void 0;
const web3_js_1 = require("@solana/web3.js");
const anchor_1 = require("@coral-xyz/anchor");
const numericConstants_1 = require("../constants/numericConstants");
const pyth_solana_receiver_1 = require("@pythnetwork/pyth-solana-receiver");
const __1 = require("..");
class PythPullClient {
    constructor(connection, multiple = numericConstants_1.ONE, stableCoin = false) {
        this.connection = connection;
        this.multiple = multiple;
        this.stableCoin = stableCoin;
        const provider = new anchor_1.AnchorProvider(this.connection, 
        //@ts-ignore
        new __1.Wallet(new web3_js_1.Keypair()), {
            commitment: connection.commitment,
        });
        this.receiver = new anchor_1.Program(pyth_solana_receiver_1.pythSolanaReceiverIdl, __1.DRIFT_ORACLE_RECEIVER_ID, provider);
        this.decodeFunc =
            this.receiver.account.priceUpdateV2.coder.accounts.decodeUnchecked.bind(this.receiver.account.priceUpdateV2.coder.accounts);
    }
    async getOraclePriceData(pricePublicKey) {
        const accountInfo = await this.connection.getAccountInfo(pricePublicKey);
        return this.getOraclePriceDataFromBuffer(accountInfo.data);
    }
    getOraclePriceDataFromBuffer(buffer) {
        const message = this.decodeFunc('priceUpdateV2', buffer);
        const priceData = message.priceMessage;
        const confidence = convertPythPrice(priceData.conf, priceData.exponent, this.multiple);
        let price = convertPythPrice(priceData.price, priceData.exponent, this.multiple);
        if (this.stableCoin) {
            price = getStableCoinPrice(price, confidence);
        }
        return {
            price,
            slot: message.postedSlot,
            confidence,
            twap: convertPythPrice(priceData.price, priceData.exponent, this.multiple),
            twapConfidence: convertPythPrice(priceData.price, priceData.exponent, this.multiple),
            hasSufficientNumberOfDataPoints: true,
        };
    }
}
exports.PythPullClient = PythPullClient;
function convertPythPrice(price, exponent, multiple) {
    exponent = Math.abs(exponent);
    const pythPrecision = numericConstants_1.TEN.pow(new anchor_1.BN(exponent).abs()).div(multiple);
    return price.mul(numericConstants_1.PRICE_PRECISION).div(pythPrecision);
}
exports.convertPythPrice = convertPythPrice;
const fiveBPS = new anchor_1.BN(500);
function getStableCoinPrice(price, confidence) {
    if (price.sub(numericConstants_1.QUOTE_PRECISION).abs().lt(anchor_1.BN.min(confidence, fiveBPS))) {
        return numericConstants_1.QUOTE_PRECISION;
    }
    else {
        return price;
    }
}
