"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.bulkPollingUserSubscribe = void 0;
/**
 * @param users
 * @param accountLoader
 */
async function bulkPollingUserSubscribe(users, accountLoader) {
    if (users.length === 0) {
        await accountLoader.load();
        return;
    }
    await Promise.all(users.map((user) => {
        return user.accountSubscriber.addToAccountLoader();
    }));
    await accountLoader.load();
    await Promise.all(users.map(async (user) => {
        return user.subscribe();
    }));
}
exports.bulkPollingUserSubscribe = bulkPollingUserSubscribe;
