"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WebSocketInsuranceFundStakeAccountSubscriber = void 0;
const types_1 = require("./types");
const events_1 = require("events");
const webSocketAccountSubscriber_1 = require("./webSocketAccountSubscriber");
class WebSocketInsuranceFundStakeAccountSubscriber {
    constructor(program, insuranceFundStakeAccountPublicKey, resubTimeoutMs, commitment) {
        this.isSubscribed = false;
        this.program = program;
        this.insuranceFundStakeAccountPublicKey =
            insuranceFundStakeAccountPublicKey;
        this.eventEmitter = new events_1.EventEmitter();
        this.resubTimeoutMs = resubTimeoutMs;
        this.commitment = commitment;
    }
    async subscribe(insuranceFundStakeAccount) {
        if (this.isSubscribed) {
            return true;
        }
        this.insuranceFundStakeDataAccountSubscriber =
            new webSocketAccountSubscriber_1.WebSocketAccountSubscriber('insuranceFundStake', this.program, this.insuranceFundStakeAccountPublicKey, undefined, {
                resubTimeoutMs: this.resubTimeoutMs,
            }, this.commitment);
        if (insuranceFundStakeAccount) {
            this.insuranceFundStakeDataAccountSubscriber.setData(insuranceFundStakeAccount);
        }
        await this.insuranceFundStakeDataAccountSubscriber.subscribe((data) => {
            this.eventEmitter.emit('insuranceFundStakeAccountUpdate', data);
            this.eventEmitter.emit('update');
        });
        this.eventEmitter.emit('update');
        this.isSubscribed = true;
        return true;
    }
    async fetch() {
        await Promise.all([this.insuranceFundStakeDataAccountSubscriber.fetch()]);
    }
    async unsubscribe() {
        if (!this.isSubscribed) {
            return;
        }
        await Promise.all([
            this.insuranceFundStakeDataAccountSubscriber.unsubscribe(),
        ]);
        this.isSubscribed = false;
    }
    assertIsSubscribed() {
        if (!this.isSubscribed) {
            throw new types_1.NotSubscribedError('You must call `subscribe` before using this function');
        }
    }
    getInsuranceFundStakeAccountAndSlot() {
        this.assertIsSubscribed();
        return this.insuranceFundStakeDataAccountSubscriber.dataAndSlot;
    }
    updateData(insuranceFundStake, slot) {
        var _a;
        const currentDataSlot = ((_a = this.insuranceFundStakeDataAccountSubscriber.dataAndSlot) === null || _a === void 0 ? void 0 : _a.slot) || 0;
        if (currentDataSlot <= slot) {
            this.insuranceFundStakeDataAccountSubscriber.setData(insuranceFundStake, slot);
            this.eventEmitter.emit('insuranceFundStakeAccountUpdate', insuranceFundStake);
            this.eventEmitter.emit('update');
        }
    }
}
exports.WebSocketInsuranceFundStakeAccountSubscriber = WebSocketInsuranceFundStakeAccountSubscriber;
